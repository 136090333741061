import * as XLSX from 'xlsx';

export const sheetDownload = async (dateFilter) => {
    try {
        const userInfo = JSON.parse(localStorage.getItem('PAuser'));
        const response = await fetch('https://anticounterfeit.co.in/api/report/sheetDownload/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                uid: userInfo.message.result.id,
                durationType: dateFilter
            })
        });
        const json = await response.json();
        if (response.ok) {
            //console.log("rawData", json.data);
            let data = json?.data.map((it, index) => {
                let DbId = it.id;
                let keyword = it.brand;
                let platform = it.platform_name;
                let url = it.prod_link;
                let seller = it.seller_name;
                let lid = it.prod_code;
                let is_official_seller = it.seller_status === "W" ? "Yes" : "No";
                let is_reported = ["R", "TD"].includes(it.status) ? "Yes" : "No";
                let is_removed = ["TD"].includes(it.status) ? "Yes" : "No";
                let is_test = it.test_purchase_status !== null ? "Yes" : "No";
                let is_counterfeit = it.test_purchase_status === 4 ? "Yes" : it.seller_status==='C' ? "Yes":"N/A";
                return {
                    "DB ID": DbId,
                    "Keyword": keyword,
                    "Platform": platform,
                    "URL": url,
                    "Seller": seller,
                    "LID": lid,
                    "Official Seller": is_official_seller,
                    "is_reported": is_reported,
                    "is_removed": is_removed,
                    "is_test_purchase": is_test,
                    "is_counterfeit": is_counterfeit
                };
            });

            // Convert data to a worksheet
            const worksheet = XLSX.utils.json_to_sheet(data);

            // Create a new workbook and add the worksheet to it
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

            // Generate an Excel file and trigger a download
            XLSX.writeFile(workbook, 'report.xlsx');

        } else if (response.status === 400) {
            //console.log(json);
        }
    } catch (e) {
        //console.log("error in sheetdownload", e);
    }
}
