import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { useListContext } from "../../../../contexts/listContext";


export default function SuspectedSellers(props) {
  const { finalData } = props;
  // const [finalData, setFinalData] = useState();
  const { list, checkedPlatforms, abuse_type, dateFilter } = useListContext();
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

 
    return (
      <Card
        direction='column'
        w='100%'
        px='10px'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex px='25px' paddingBottom='10px' justify='space-between' align='center'>
          <Text
            color={textColor}
            fontSize='22px'
            fontWeight='700'
            lineHeight='100%'
            m='auto'
          >
            Top Suspected Sellers
          </Text>
        </Flex>
        <Table variant='simple' color='gray.500' mb='24px'>
          <Thead>
            <Tr>
              <Th pe='10px' borderColor={borderColor}>
                <Flex justify='space-between' align='center' fontSize='12px' color='gray.400'>
                  Sellers
                </Flex>
              </Th>
              <Th pe='10px' borderColor={borderColor}>
                <Flex justify='space-between' align='center' fontSize='12px' color='gray.400'>
                  Count
                </Flex>
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {finalData?.map((row, index) => (
              <Tr key={index}>
                <Td fontSize='14px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  {row.sellers || "Unknown"}
                </Td>
                <Td fontSize='14px' minW={{ sm: "150px", md: "200px", lg: "auto" }} borderColor='transparent'>
                  {row.count}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Card>
    );
  }


