import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from 'react-router-dom';

// Chakra imports
import {
    Flex,
    Icon,
    Image,
    Text,
    useColorModeValue,
    Link,
    Button,
    IconButton,
    Tooltip,
    useClipboard
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { StarIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico';

import { MdCheckCircle, MdCancel, MdOutlineError, MdShoppingCart, MdContentCopy, MdDelete, MdEdit } from "react-icons/md";
function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Item(props) {
    // Extract brand parameter from the URL
    const query = useQuery();
    const brand = query.get('brand');
    // Chakra Color Mode
    const textColor = useColorModeValue("brands.900", "white");
    const bgItem = useColorModeValue(
        { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
        { bg: "navy.700", boxShadow: "unset" }
    );
    const textColorDate = useColorModeValue("secondaryGray.600", "white");

    const { title, seller_name, brand_name, platform_id, image_link, prod_link } = props;

    return (
        <Link
            href={prod_link}
            target="_blank">

            <Card
                _hover={bgItem}
                bg='transparent'
                boxShadow='unset'
                px='24px'
                py='21px'
                transition='0.2s linear'>
                <Flex direction={{ base: "column" }} justify='center'>
                    <Flex position='relative' align='center'>
                        <Link href={image_link} isExternal me='15px'>
                            <Image src={image_link} w='100px' h='130px' objectFit='contain'  me='16px' />
                        </Link>
                        <Flex
                            direction='column'
                            w={{ base: "70%", md: "100%" }}
                            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>

                            <Text
                                color={textColor}
                                fontSize={{
                                    base: "md",
                                }}
                                mb='5px'
                                fontWeight='bold'
                                me='14px'
                            >
                                {/* 1.&nbsp;&nbsp; */}
                                {/* Seller name: &nbsp;&nbsp; */}
                                <Link textDecoration='underline' href={prod_link} target="_blank">
                                    {title} {/* Use brand parameter here as the title */}
                                </Link>
                            </Text>
                          
                            <Flex direction='row'>
                                <Image src={platform_id === 4 ? Meesho : platform_id === 5 ? Glowroad : Glowroad} width="20px" />
                                &nbsp;&nbsp;
                                <Text
                                    color='secondaryGray.600'
                                    fontSize={{
                                        base: "sm",
                                    }}
                                    fontWeight='400'
                                    me='14px'>
                                    {seller_name}
                                </Text>
                               
                            </Flex>
                            
                        </Flex>
                    </Flex>
                </Flex>
            </Card>
        </Link>
    );
}
