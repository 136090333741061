import React, { useEffect, useState } from "react";
import { useLocation } from 'react-router-dom';

// Chakra imports
import {
    Box,
    Button,
    Flex,
    Stack,
    Alert,
    AlertIcon,
    Text
} from "@chakra-ui/react";

// Custom components
import SellerCard from "./subComponents/SellerCard";
import Card from "components/card/Card.js";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default function Products() {
    const query = useQuery();
    const brand = decodeURIComponent(query.get('brand')); // Decode the brand parameter
    const [sellers, setSellers] = useState([]);
    const [displayedSellers, setDisplayedSellers] = useState([]); // State to manage displayed sellers
    const [totalSellers, setTotalSellers] = useState(0); // State to track total sellers count
    const [successAlert, setSuccessAlert] = useState('');
    const [failureAlert, setFailureAlert] = useState('');
    const [loadMore, setLoadMore] = useState(20); // State to track the number of sellers to display

    const handleSuccessAlert = async (alertMessage) => {
        setSuccessAlert(alertMessage);
        setTimeout(() => {
            setSuccessAlert('');
        }, 5000);
    }

    const handleFailureAlert = async (alertMessage) => {
        setFailureAlert(alertMessage);
        setTimeout(() => {
            setFailureAlert('');
        }, 5000);
    }

    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('PAuser'));
        const uid = userInfo?.message?.result?.id;

        const fetchSellers = async () => {
            try {
                const response = await fetch("https://anticounterfeit.co.in/api/general/getSellers", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid, brand_name: brand }), // Send uid and brand_name
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const data = await response.json();
                console.log(data);
                setSellers(data); // Assuming the API returns an array of sellers
                setDisplayedSellers(data.slice(0, loadMore)); // Initially display only 20 sellers
                setTotalSellers(data.length); // Set total sellers count
            } catch (error) {
                console.error("Error fetching sellers:", error);
            }
        };

        if (uid && brand) {
            fetchSellers(); // Call API only if uid and brand are available
        }
    }, [brand, loadMore]); // Add uid and brand as dependencies

    // Load more sellers on button click
    const handleLoadMore = () => {
        const nextLoad = loadMore + 20;
        setDisplayedSellers(sellers.slice(0, nextLoad));
        setLoadMore(nextLoad);
    }

    return (
        <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
            {/* Display total sellers count */}
            <Flex justify="start" mb={4}>
                <Text fontSize="lg" fontWeight="bold">
                    Total Sellers Found: {totalSellers}
                </Text>
            </Flex>

            {/* Main Fields */}
            <Card p='0px' w='100%'>
                <Flex
                    align={{ sm: "flex-start", lg: "center" }}
                    justify='space-between'
                    w='100%'
                    px='22px'
                >
                </Flex>
                {displayedSellers.map((it, index) => (
                    <SellerCard
                        key={index}
                        title={it.title}
                        seller_name={it.seller_name}
                        brand_name={it.brand_name}
                        platform_id={it.platform_id}
                        image_link={it.image_link}
                        prod_link={it.prod_link}
                    />
                ))}
            </Card>

            {displayedSellers.length < sellers.length && (
                <Flex justify="center" mt={4}>
                    <Button onClick={handleLoadMore}>
                        Load More
                    </Button>
                </Flex>
            )}

            <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
                {successAlert !== '' &&
                    <Alert status='success' variant='left-accent' w='40vw'>
                        <AlertIcon />
                        {successAlert}
                    </Alert>
                }
                {failureAlert !== '' &&
                    <Alert status='error' variant='left-accent' w='40vw'>
                        <AlertIcon />
                        {failureAlert}
                    </Alert>
                }
            </Stack>
        </Box>
    );
}
