import React, { useEffect } from "react";
import { useState } from "react";

// Chakra imports
import {
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  Link,
  Button,
  IconButton,
  Tooltip,
  useClipboard
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { StarIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico';

import { MdCheckCircle, MdCancel, MdOutlineError, MdShoppingCart, MdContentCopy, MdDelete, MdEdit } from "react-icons/md";
import EditListing from "components/EditListing";
import { IoMdAlert } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";








export default function Item(props) {
  const { product, handleInsertSeller, removeFromInsert, statusUpdate, setStatusUpdate, fetchProducts, index, keywordOptions } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  const textColorDate = useColorModeValue("secondaryGray.600", "white");
  const { hasCopied, onCopy } = useClipboard(product.prod_code);
  const [toggleWhiteList, setToggleWhiteList] = useState(false);
  const [toggleSuspicious, setToggleSuspicious] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [save, setSave] = useState(false);
  // const [keywordOptions, setKeywordOptions] = useState([]);


  const handleToggleWhiteList = async (product) => {
    setToggleWhiteList(!toggleWhiteList);
    setToggleSuspicious(false);
    // setToggleTest(false);
    setToggleDelete(false);

    if (!toggleWhiteList) {
      await handleInsertSeller(product, 'W');
    } else {
      await removeFromInsert(product, 'W');
    }
  }
  const handleToggleSuspicious = async (product) => {
    setToggleWhiteList(false);
    setToggleSuspicious(!toggleSuspicious);
    setToggleDelete(false);
    if (!toggleSuspicious) {
      await handleInsertSeller(product, 'S');
    } else {
      await removeFromInsert(product, 'S');
    }
  }

  const handleToggleDelete = async (product) => {
    setToggleWhiteList(false);
    setToggleSuspicious(false);
    setToggleDelete(!toggleDelete);
    if (!toggleDelete) {
      await handleInsertSeller(product, 'X');
    } else {
      await removeFromInsert(product, 'X');
    }
  }

  useEffect(() => {
    setToggleWhiteList(false);
    setToggleSuspicious(false);
    setToggleDelete(false);
    setStatusUpdate(false)
  }, [statusUpdate])

  // const handleToggleTest = async (keyword_id, platform_id, seller_name) => {
  //   setToggleWhiteList(false);
  //   setToggleSuspicious(false);
  //   setToggleTest(!toggleTest);
  //   if (!toggleTest) {
  //     await handleInsertSeller(keyword_id, platform_id, seller_name, 'T');
  //     setToggleTest(!toggleTest);
  //   } else {
  //     await removeFromInsert(keyword_id, platform_id, seller_name, 'T');
  //   }
  // }





  const getPlatformIcon = (platformId) => {
    switch (platformId) {
      case 1:
        return Amazon; // Use imported Amazon icon
      case 2:
        return Flipkart; // Use imported Flipkart icon
      case 3:
        return Snapdeal; // Use imported Snapdeal icon
      case 4:
        return Meesho;
      case 5:
        return Glowroad
    }
  };
  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const totalStars = filledStars + (hasHalfStar ? 1 : 0);
    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<Icon as={StarIcon} color="gold" key={`star-${i}`} />);
    }

    if (hasHalfStar) {
      stars.push(<Icon as={StarIcon} color="gold" key={`half-star`} />);
    }

    const emptyStars = 5 - totalStars;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Icon as={StarIcon} color="gray.300" key={`empty-star-${i}`} />);
    }

    return stars;
  };

  const handleSave = (async () => {
    setSave(true);
    // setEditMode(false);
  });

  const toolTipMessage = (status) => {
    if (status === 10) {
      return "Tested and verified seller";
    }
    if (status === 11) {
      return "Official Seller"
    }
    if (status === 20) {
      return "Test purchase and found Counterfeit products"
    }
    if (status === 21) {
      return "Blacklisted Seller in our system"
    }
    if (!status) {
      return "No information available"
    }
  }


  return (
    <Card
      _hover={bgItem}
      bg='transparent'
      boxShadow='unset'
      px='24px'
      py='21px'
      transition='0.2s linear'>
      <Flex direction={{ base: "column" }} justify='center'>
        <Flex position='relative' align='center'>
          <Link href={product.image_link} isExternal me='20px'>
            <Image src={product.image_link} w='80px' me='16px' />
          </Link>
          <Flex
            direction='column'
            w={{ base: "70%", md: "100%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>

            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb='5px'
              fontWeight='bold'
              me='14px'
            >
              {index + 1}.&nbsp;&nbsp;

              <Link href={product.prod_link} textDecoration='underline' isExternal>
                {product.title}
              </Link>
            </Text>
            <Image src={getPlatformIcon(product.platform_id)} width="15px" />
            {!editMode ? <>
              <Flex direction='row'>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  {product.keyword}

                </Text>

              </Flex>

              <Flex direction='row'>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  Store Name:
                </Text>
                <Text
                  color={textColor}
                  fontSize={{
                    base: "sm",
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'
                >
                  {product.store_name}
                </Text>
              </Flex>

              <Flex direction='row'>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  Product Code
                </Text>
                <Text
                  color={textColor}
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='bold'
                >
                  {product.prod_code}
                </Text>
                <Tooltip label={hasCopied ? "Copied!" : "Copy to Clipboard"}>
                  <IconButton
                    aria-label="Copy to Clipboard"
                    icon={<MdContentCopy />}
                    onClick={onCopy}
                    variant="ghost"
                    colorScheme={hasCopied ? "green" : "gray"}
                    height='17px'
                  />
                </Tooltip>

              </Flex>
              <Flex direction='row'>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  Seller Name:
                </Text>
                <Text
                  color={textColor}
                  fontSize={{
                    base: "sm",
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'
                >
                  {product.seller_name}
                </Text>
                {product.global_status &&
                  <Tooltip label={toolTipMessage(product.global_status)}>
                    <IconButton
                      aria-label="Copy to Clipboard"
                      icon={(product.global_status === 20 || product.global_status === 21) ?
                        <IoMdAlert />
                        :
                        (product.global_status === 11 || product.global_status === 10) && <MdCheckCircle />}
                      variant="ghost"
                      colorScheme={(product.global_status === 21 || product.global_status === 20) ?
                        "red"
                        :
                        (product.global_status === 10|| product.global_status === 11) && "green"}
                      height='17px'
                    />
                  </Tooltip>
                }
              </Flex>
              <Flex direction='row'>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  MRP :
                </Text>
                <Text
                  color={textColor}
                  fontSize={{
                    base: "sm",
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'
                >
                  Rs. {product.mrp}
                </Text>
              </Flex>
              <Flex direction='row'>
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  Selling Price:
                </Text>
                <Text
                  color={textColor}
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='semibold'
                  mb='5px'
                  me='14px'
                >
                  Rs. {product.selling_price}
                </Text>
              </Flex>
            </> : <EditListing
              psm_id={product.psm_id}
              mrp={product.mrp}
              seller_name={product.seller_name}
              selling_price={product.selling_price}
              prod_code={product.prod_code}
              setEditMode={setEditMode}
              save={save}
              setSave={setSave}
              fetchProducts={fetchProducts}
              keyword={product.keyword}
              keywordOptions={keywordOptions}
              pd_id={product.pd_id}
              store_name={product.store_name}
            />}
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Manufacturer:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                {product.manufacturer}
              </Text>
            </Flex>

            <Flex direction='row'>
              {renderStars(product.overall_rating)}
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                &nbsp;&nbsp;{product.no_of_rating}
              </Text>
            </Flex>
          </Flex>
          <Flex
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
            align='center'
            direction="column"
          >
            <Tooltip label='Official Seller'>
              <Button colorScheme='gray' fontSize="20px" p='0px' onClick={() => handleToggleWhiteList(product)}>
                <Icon color={toggleWhiteList === true ? 'green' : 'gray'} as={MdCheckCircle} />
              </Button>
            </Tooltip>
            <Tooltip label='Suspicious'>
              <Button colorScheme="gray" fontSize="20px" p='0px'
                onClick={() => handleToggleSuspicious(product)}>
                <Icon color={toggleSuspicious === true ? 'gold' : 'gray'} as={MdOutlineError} />
              </Button>
            </Tooltip>
            <Tooltip label='Delete Product'>
              <Button colorScheme="gray" fontSize="20px" p='0px'
                onClick={() => handleToggleDelete(product)}>
                <Icon color={toggleDelete === true ? 'red.600' : 'gray'} as={MdDelete} />
              </Button>
            </Tooltip>
            {!editMode ?
              <Tooltip label='Edit Details'>
                <Button colorScheme="gray" fontSize="20px" p='0px'
                  onClick={() => setEditMode(true)}>
                  <Icon color='gray' as={MdEdit} />
                </Button>
              </Tooltip>
              :
              <Tooltip label='Save Changes' >
                <Button colorScheme="messenger" fontSize="20px" p='0px'
                  onClick={() => handleSave()}>
                  <Text fontSize='10px'>Save</Text>
                </Button>
              </Tooltip>
            }
            {/* <Tooltip label='Test Purchase'>
              <Button colorScheme="gray" fontSize="20px" p='0px'
                onClick={() => handleToggleTest(product.keyword_id, product.platform_id, product.seller_name)}>
                <Icon color={toggleTest === true ? 'blue' : 'gray'} as={MdShoppingCart} />
              </Button>
            </Tooltip> */}

          </Flex>

        </Flex>

      </Flex>
    </Card>
  );
}
