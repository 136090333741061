// Chakra imports
import {
  AvatarGroup,
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import React, { useState } from "react";
import { IoHeart, IoHeartOutline } from "react-icons/io5";

export default function NFT(props) {
  const { query_image_name,
    brand_name,
    query_image_url,
    similarity_percentage,
    site_link,
    title,
    platform_name,
  platform_icon} = props;
  const textColor = useColorModeValue("navy.700", "white");
  const textColorBid = useColorModeValue("brand.500", "white");
  return (
    <Card p='20px'>
      <Link
        href={site_link}
        target="_blank"
        rel="noopener noreferrer"
        mt={{
          base: "0px",
          md: "10px",
          lg: "0px",
          xl: "10px",
          "2xl": "0px",
        }}
      >

        <Flex direction={{ base: "column" }} justify='center'>
          <Box mb={{ base: "20px", "2xl": "20px" }} position='relative' display='flex' justifyContent='center'>
            <Image
              src={query_image_url}
              width="200px"
              height="200px"
              objectFit="cover"
              borderRadius="20px"
            />
            </Box>

          <Flex flexDirection='column' justify='space-between' h='100%'>
            <Flex
              justify='space-between'
              direction={{
                base: "row",
                md: "column",
                lg: "row",
                xl: "column",
                "2xl": "row",
              }}
              mb='auto'>
              <Flex direction='column'>
                <Text
                  color={textColor}
                  fontSize={{
                    base: "xl",
                    md: "lg",
                    lg: "lg",
                    xl: "lg",
                    "2xl": "md",
                    "3xl": "lg",
                  }}
                  mb='5px'
                  fontWeight='bold'
                  me='14px'>
                  {title?.length > 50 ? `${title.slice(0, 50)}...` : title}
                </Text>
                <Flex direction='row'>
                  <Image
                    src={platform_icon}
                    width="17px"
                    height="17px"
                    mr="8px"
                    // objectFit="cover"
                    // borderRadius="20px"
                  />
                <Text
                  color='secondaryGray.600'
                  fontSize={{
                    base: "sm",
                  }}
                  fontWeight='400'
                  me='14px'>
                  {platform_name}
                  </Text>
                </Flex>
              </Flex>

            </Flex>
            <Flex
              align='start'
              justify='space-between'
              direction={{
                base: "row",
                md: "column",
                lg: "row",
                xl: "column",
                "2xl": "row",
              }}
              mt='25px'>
              <Text fontWeight='700' fontSize='sm' color={textColorBid}>
               Match Percentage: {similarity_percentage}%
              </Text>



            </Flex>
          </Flex>
        </Flex>
      </Link>
    </Card>
  );
}
