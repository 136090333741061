import Tile from "./components/Tile";
import Tile2 from "./components/Tile2";

import React from "react";
import Nft1 from "../../../assets/img/avatars/avatar1.png";
import { MdCheck, MdDone } from "react-icons/md";
import {
    Box,
    Button,
    Flex,
    Grid,
    Link,
    Text,
    useColorModeValue,
    SimpleGrid,
    Heading,
    Progress,


} from "@chakra-ui/react";

import Card from "components/card/Card";
// import Loader from "./components/Loader";


const Result = ({ result, setShowResult, status, setStatus, secondResult }) => {
    const textColor = useColorModeValue("secondaryGray.900", "white");



    return (
        <>
            {result.length === 0 ? <Card p='20px' align='center' direction='column' w='100%'>
                <Flex
                    align={{ sm: "flex-start", lg: "center" }}
                    justify='space-between'
                    w='100%'
                    px='22px'
                    py='18px'>
                    {/* <SearchBar m="10px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} /> */}
                    <Heading>Search Results</Heading>
                    <Button variant='action' alignSelf='right' onClick={() => {
                        setShowResult(false)
                        setStatus(0)

                    }}>Go Back</Button>
                </Flex>

                {status >= 0 &&
                    <Flex align="center" color={textColor} fontSize="l" ms="24px" fontWeight="700" pl="20px">
                        <MdDone color="#5ffe43" fontWeight="bold" fontSize="20px" style={{ margin: '4px' }} />
                        <Text>Uploaded</Text>
                    </Flex>
                }
                {status === 1 &&
                    <Flex align="center" color={textColor} fontSize="l" ms="24px" fontWeight="700" pl="20px">
                        {/* {status > 1 && <MdDone color="#5ffe43" fontWeight="bold" fontSize="20px" style={{ margin: '4px' }} />} */}
                        <Text>Extracting Features and Searching Web</Text>
                    </Flex>
                }
                {status >= 2 &&
                    <Flex align="center" color={textColor} fontSize="l" ms="24px" fontWeight="700" pl="20px">
                        {status >= 2 && <MdDone color="#5ffe43" fontWeight="bold" fontSize="20px" style={{ margin: '4px' }} />}
                        <Text>Searching Web</Text>
                    </Flex>
                }
                {status > 1 &&
                    <Flex align="center" color={textColor} fontSize="l" ms="24px" fontWeight="700" pl="20px">
                        {status > 3 && <MdDone color="#5ffe43" fontWeight="bold" fontSize="20px" style={{ margin: '4px' }} />}
                        <Text>Extracting Features</Text>
                    </Flex>
                }
                {status >= 4 &&
                    <Flex align="center" color={textColor} fontSize="l" ms="24px" fontWeight="700" pl="20px">
                        {status > 4 && <MdDone color="#5ffe43" fontWeight="bold" fontSize="20px" style={{ margin: '4px' }} />}
                        <Text>Matching Results</Text>
                    </Flex>
                }
                {status >= 5 &&
                    <Flex align="center" color={textColor} fontSize="l" ms="24px" fontWeight="700" pl="20px">
                        <MdDone color="#5ffe43" fontWeight="bold" fontSize="20px" style={{ margin: '4px' }} />
                        <Text>Done</Text>
                    </Flex>
                }

                <Progress size='md' colorScheme="blue" isIndeterminate w="90%" margin="auto" />
                {/* <Loader activeStep={activeStep} setActiveStep={setActiveStep} /> */}
            </Card > :
                <Flex
                    align={{ sm: "flex-start", lg: "center" }}
                    justify='space-between'
                    w='100%'
                    px='22px'
                    py='18px'>
                    {/* <SearchBar m="10px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} /> */}
                    <Heading>Search Results</Heading>
                    <Button variant='action' alignSelf='right' onClick={() => {
                        setShowResult(false)
                        setStatus(0)
                        

                    }}>Go Back</Button>
                </Flex>
            }
            <SimpleGrid columns={{ base: 2, sm: 2, md: 3 }} mb='20px' gap='20px'>
                {result && result?.map((item, index) => (
                    <Tile
                        key={index}
                        query_image_name={item.query_image_name}
                        brand_name={item.brand_name}
                        query_image_url={item.query_image_url}
                        similarity_percentage={item.similarity_percentage}
                        site_link={item.site_link}
                        title={item.title}
                        platform_icon={item.platform_icon}
                        platform_name={item.platform_name}
                    />
                ))}

            </SimpleGrid>
            {secondResult && secondResult.map((arr, index) => (
                <SimpleGrid columns={{ base: 2, sm: 2, md: 3 }} mb='20px' gap='20px' key={index}>

                    {arr.top_matches.map((item, ind) => (
                        <Tile2
                            key={ind}
                            brand_name={item.category}
                            title={item.brand}
                            query_image_url={item.image_url}
                            platform_name={item.category}
                            similarity_percentage={item.similarity_percentage}
                        />
                    ))}
                </SimpleGrid >

            ))}

        </>
    );
};

export default Result;
