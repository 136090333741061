import React, { useEffect, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import FirstPage from './FirstPage';
import SecondPage from './SecondPage';
import ThirdPage from './ThirdPage';
import FourthPage from './FourthPage';
import FifthPage from './FifthPage';
import './index.css';
import { useListContext } from "../../../../contexts/listContext";


const PDFDocument = () => {
    const contentRef = useRef();


    const userInfo = JSON.parse(localStorage.getItem('PAuser'));

    // const { list, checkedPlatforms, abuse_type, dateFilter, setDateFilter } = useListContext();

    const [topSuspectedBrands, setTopSuspectedBrands] = useState([]);
    const [topSuspectedSellers, setTopSuspectedSellers] = useState([]);
    const [topInfringingPlatforms, setTopInfringingPlatforms] = useState([]);
    const [brandWise, setBrandWise] = useState([]);
    const [platformWise, setPlatformWise] = useState([]);
    const [test, setTest] = useState([]);
    const [dateFilter, setDateFilter] = useState({});
    const [name, setName] = useState("");

    useEffect(() => {
        const searchParams = new URLSearchParams(location.search);
        const dateFilter = searchParams.get('dateFilter');
        const name = searchParams.get('brand');
        setName(name);
        setDateFilter(JSON.parse(dateFilter));
    }, [location]);


    const fetchTopSuspectedBrand = async () => {
        try {
            //console.log("param", userInfo.message.result.id);
            const response = await fetch('https://anticounterfeit.co.in/api/report/topSuspectedBrands', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uid: userInfo.message.result.id,
                    durationType: dateFilter
                })
            });
            const json = await response.json();
            if (response.ok) {
                //console.log('TopSuspectedBrands fetched')
                setTopSuspectedBrands(json.data)
                //console.log("topSuspectedBrands", json.data);
            } else if (response.status !== 400) {
                //console.log(json);
                //console.log("Error Fetching Data");
                handleFailureAlert("Error fetching Data");
            }
        } catch (e) {
            //console.log("error in fetchProducts");
        }
    };

    const fetchTopSuspectedSellers = async () => {
        try {
            //console.log("param", userInfo.message.result.id);
            const response = await fetch('https://anticounterfeit.co.in/api/report/topSuspectedSellers', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uid: userInfo.message.result.id,
                    durationType: dateFilter
                })
            });
            const json = await response.json();
            if (response.ok) {
                //console.log('TopSuspectedSellers fetched')
                setTopSuspectedSellers(json.data)
                //console.log("TopSuspectedSellers", json.data);
            } else if (response.status !== 400) {
                //console.log(json);
                //console.log("Error Fetching Data");
                handleFailureAlert("Error fetching Data");
            }
        } catch (e) {
            //console.log("error in setTopSuspectedSellers");
        }
    };

    const fetchTopInfringingPlatforms = async () => {
        try {
            //console.log("param", userInfo.message.result.id);
            const response = await fetch('https://anticounterfeit.co.in/api/report/topInfringingPlatforms', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uid: userInfo.message.result.id,
                    durationType: dateFilter
                })
            });
            const json = await response.json();
            if (response.ok) {
                //console.log('TopInfringingPlatforms fetched')
                setTopInfringingPlatforms(json.data)
                //console.log("TopInfringingPlatforms", json.data);
            } else if (response.status !== 400) {
                //console.log(json);
                //console.log("Error Fetching Data");
                handleFailureAlert("Error fetching Data");
            }
        } catch (e) {
            //console.log("error in TopInfringingPlatforms");
        }
    };

    const fetchBrandWise = async () => {
        try {
            //console.log("param", userInfo.message.result.id);
            const response = await fetch('https://anticounterfeit.co.in/api/report/brandWise', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uid: userInfo.message.result.id,
                    durationType: dateFilter
                })
            });
            const json = await response.json();
            if (response.ok) {
                //console.log('setBrandWise fetched')
                setBrandWise(json.data)
                //console.log("setBrandWise", json.data);
            } else if (response.status !== 400) {
                //console.log(json);
                //console.log("Error Fetching Data");
                handleFailureAlert("Error fetching Data");
            }
        } catch (e) {
            //console.log("error in setBrandWise");
        }
    };

    const fetchPlatformWise = async () => {
        try {
            //console.log("param", userInfo.message.result.id);
            const response = await fetch('https://anticounterfeit.co.in/api/report/platformWise', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uid: userInfo.message.result.id,
                    durationType: dateFilter
                })
            });
            const json = await response.json();
            if (response.ok) {
                //console.log('PlatformWise fetched')
                setPlatformWise(json.data)
                //console.log("PlatformWise", json.data);
            } else if (response.status !== 400) {
                //console.log(json);
                //console.log("Error Fetching Data");
                handleFailureAlert("Error fetching Data");
            }
        } catch (e) {
            //console.log("error in PlatformWise");
        }
    };

    const fetchTest = async () => {
        try {
            //console.log("param", userInfo.message.result.id);
            const response = await fetch('https://anticounterfeit.co.in/api/report/testCount', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    uid: userInfo.message.result.id,
                    durationType: dateFilter
                })
            });
            const json = await response.json();
            if (response.ok) {
                //console.log('testCount fetched')
                setTest(json.data)
                //console.log("testCoutn", json.data);
            } else if (response.status !== 200) {
                //console.log(json);
                //console.log("Error Fetching Data");
                handleFailureAlert("Error fetching Data");
            }
        } catch (e) {
            //console.log("error in testCount", e);
        }
    };

    useEffect(() => {

        fetchTopSuspectedBrand();
        fetchTopSuspectedSellers();
        fetchTopInfringingPlatforms();
        fetchBrandWise();
        fetchPlatformWise();

        fetchTest();
    }, [userInfo?.message?.result.id, dateFilter]);



    const handleDownload = async () => {
        const content = contentRef.current;

        await html2canvas(content, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                orientation: 'portrait',
                unit: 'pt',
                format: 'a4',
            });

            const pageWidth = 595.28; // A4 page width in points
            const pageHeight = 841.89; // A4 page height in points
            const imgWidth = pageWidth;
            const imgHeight = (canvas.height * pageWidth) / canvas.width;

            let heightLeft = imgHeight;
            let position = 0;

            // Add the first page
            pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
            heightLeft -= pageHeight;

            // Add subsequent pages
            while (heightLeft >= 0) {
                position = heightLeft - imgHeight;
                pdf.addPage();
                pdf.addImage(imgData, 'PNG', 0, position, imgWidth, imgHeight);
                heightLeft -= pageHeight;
            }

            pdf.save('document.pdf');
        });
        //  window.close();
    };
    // useEffect(async() => {
    //     handleDownload();
    //     // window.close();
    // }, [])

    return (
        <div>
            <button onClick={handleDownload} className="download-button">Download PDF</button>

            <div ref={contentRef} className="pdf-container">
                <FirstPage brandName={name} />
                <SecondPage topSuspectedBrands={topSuspectedBrands}
                    topSuspectedSellers={topSuspectedSellers}
                    topInfringingPlatforms={topInfringingPlatforms} />
                <ThirdPage brandWise={brandWise}
                    platformWise={platformWise} />
                <FourthPage test={test} />
                <FifthPage />
            </div>

            <button onClick={handleDownload} className="download-button">Download PDF</button>
        </div>
    );
};

export default PDFDocument;
