import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Spinner, // Import Spinner for loading indication
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { useListContext } from "../../../../contexts/listContext";

export default function SuspectedBrandsTable(props) {
  const { finalData } = props;
  const { list, checkedPlatforms, abuse_type, dateFilter } = useListContext();

  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const [loading, setLoading] = useState(true); // Track loading state

  useEffect(() => {
    // Set loading to false when finalData is available
    if (finalData) {
      setLoading(false);
    }
  }, [finalData]);

  const totalAmountSaved = (finalData) => {
    let amt = 0;
    finalData?.forEach((it) => {
      amt += it.revenue;
    });
    return formatIndianNumber(amt);
  };

  const formatIndianNumber = (number) => {
    return number.toLocaleString('en-IN');
  };

  if (loading) {
    // Render loader if loading
    return (
      <Flex justify="center" align="center" height="100%">
        <Spinner size="lg" />
      </Flex>
    );
  }

  return (
    <Card
      direction='column'
      w='100%'
      px='10px'
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px='25px' paddingBottom='10px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'
          m='auto'
        >
          Revenue Saved BrandWise
        </Text>
      </Flex>
      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          <Tr>
            <Th pe='10px' borderColor={borderColor}>
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>
                Brands
              </Flex>
            </Th>
            <Th pe='10px' borderColor={borderColor}>
              <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>
                Amount Saved
              </Flex>
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {finalData && finalData?.map((row, index) => (
            <Tr key={index}>
              <Td
                fontSize={{ sm: "14px" }}
                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                borderColor='transparent'
              >
                {row.name}
              </Td>
              <Td
                fontSize={{ sm: "14px" }}
                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                borderColor='transparent'
                color='gray.800'>
                Rs. {formatIndianNumber(row.revenue)}
              </Td>
            </Tr>
          ))}
        </Tbody>
        <Thead>
          <Tr>
            <Th pe='10px' borderColor={borderColor}></Th>
            <Th pe='10px' borderColor={borderColor}></Th>
          </Tr>
        </Thead>
        <Tr>
          <Th pe='10px' borderColor={borderColor}>
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>
              Total Amount Saved
            </Flex>
          </Th>
          <Th pe='10px' borderColor={borderColor}>
            <Flex justify='space-between' align='center' fontSize={{ sm: "10px", lg: "12px" }} color='gray.400'>
              {totalAmountSaved(finalData)}
            </Flex>
          </Th>
        </Tr>
      </Table>
    </Card>
  );
}
