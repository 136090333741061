import React, { useEffect } from "react";
import { useState } from "react";

// Chakra imports
import {
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  Link,
  Button,
  IconButton,
  Tooltip,
  useClipboard,
  useEditable,
  Select,
  Box,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { StarIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico';
import Shopsy from 'assets/img/shopsy.ico';


import { MdCheckCircle, MdCancel, MdOutlineError, MdShoppingCart, MdContentCopy, MdDelete, } from "react-icons/md";
import { IoMdAlert } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";



const Triangle = () => (
  <Tooltip label="This Listing Contains at least 1 Official Seller">
    <Box
      pos="absolute"
      top={0}
      left={0}
      w={0}
      h={0}
      borderBottom="50px solid transparent"
      borderLeft="50px solid green"
    />
  </Tooltip>
);

export default function Item(props) {
  const { product, handleInsertSeller, removeFromInsert, statusUpdate, setStatusUpdate, index, abuseTypes, handleInsertAbuse } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  const textColorDate = useColorModeValue("secondaryGray.600", "white");
  const { hasCopied, onCopy } = useClipboard(product.prod_code);
  const [toggleWhiteList, setToggleWhiteList] = useState(false);
  const [toggleDelete, setToggleDelete] = useState(false);
  const [toggleTest, setToggleTest] = useState(false);
  const [toggleBlackList, setToggleBlackList] = useState(false);
  // const [abuseType, setAbuseType] = useState('');

  const handleToggleWhiteList = async (product) => {
    setToggleWhiteList(!toggleWhiteList);
    setToggleTest(false);
    setToggleBlackList(false);
    setToggleDelete(false)

    if (!toggleWhiteList) {
      await handleInsertSeller(product, 'W');
      setToggleWhiteList(!toggleWhiteList);

    } else {
      await removeFromInsert(product, 'W');
    }
  }
  // const handleToggleReport = async (keyword_id, platform_id, seller_name) => {
  //   setToggleWhiteList(false);
  //   setToggleReport(!toggleReport);
  //   setToggleTest(false);
  //   setToggleBlackList(false);
  //   if (!toggleReport) {
  //     await handleInsertSeller(keyword_id, platform_id, seller_name, 'R');
  //   } else {
  //     await removeFromInsert(keyword_id, platform_id, seller_name, 'R');
  //   }
  // }
  const handleToggleTest = async (product) => {
    setToggleWhiteList(false);
    setToggleTest(!toggleTest);
    setToggleBlackList(false);
    setToggleDelete(false)

    if (!toggleTest) {
      await handleInsertSeller(product, 'T');
      setToggleTest(!toggleTest);

    } else {
      await removeFromInsert(product, 'T');
    }
  }
  const handleToggleBlackList = async (product) => {
    setToggleWhiteList(false);
    setToggleTest(false);
    setToggleBlackList(!toggleBlackList);
    setToggleDelete(false)
    if (!toggleBlackList) {
      await handleInsertSeller(product, 'R');
      setToggleBlackList(!toggleBlackList);

    } else {
      await removeFromInsert(product, 'R');
    }
  }
  const handleToggleDelete = async (product) => {
    setToggleWhiteList(false);
    setToggleTest(false);
    setToggleBlackList(false);
    setToggleDelete(!toggleDelete)
    if (!toggleBlackList) {
      await handleInsertSeller(product, 'X');

    } else {
      await removeFromInsert(product, 'X');
    }
  }

  const handleAbuseType = async (abuse_type) => {
    if (abuse_type === '') await handleInsertAbuse(product, '0');
    else await handleInsertAbuse(product, abuse_type);
  }

  useEffect(() => {
    setToggleWhiteList(false);
    setToggleTest(false);
    setToggleBlackList(false);
    setToggleDelete(false);
    setStatusUpdate(false);
  }, [statusUpdate])


  const getPlatformIcon = (platformId) => {
    switch (platformId) {
      case 1:
        return Amazon; // Use imported Amazon icon
      case 2:
        return Flipkart; // Use imported Flipkart icon
      case 3:
        return Snapdeal;
      case 4:
        return Meesho;// Use imported Snapdeal icon
      case 5:
        return Glowroad;
      case 6:
        return Shopsy
    }
  };
  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const totalStars = filledStars + (hasHalfStar ? 1 : 0);
    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<Icon as={StarIcon} color="gold" key={`star-${i}`} />);
    }

    if (hasHalfStar) {
      stars.push(<Icon as={StarIcon} color="gold" key={`half-star`} />);
    }

    const emptyStars = 5 - totalStars;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Icon as={StarIcon} color="gray.300" key={`empty-star-${i}`} />);
    }

    return stars;
  };

  const chooseAbuseType = () => {
    if (product.abuse_type_id === 0 || product.abuse_type_id === 10) {
      return 'None';
    } else if (product.abuse_type_id === 1 || product.abuse_type_id === 9) {
      return 'MRP Abuse';
    } else if (product.abuse_type_id === 2) {
      return 'Price Abuse';
    } else if (product.abuse_type_id === 3 || product.abuse_type_id === 8) {
      return 'Manufacturing Abuse';
    } else if (product.abuse_type_id === 4 || product.abuse_type_id === 7) {
      return 'Brand Abuse';
    } else if (product.abuse_type_id === 5) {
      return 'Image Abuse';
    } else if (product.abuse_type_id === 6) {
      return 'Counterfeit';
    } else if (product.abuse_type_id === null) {
      return 'Choose Type';
    } else {
      return '';
    }

  }
  //abuse_type_id=9 for mrp abuse
  //abuse_type_id=8 for manufacturing abuse
  // 7 for suspected brand abuse
  // 6 for no abuse detected

  const toolTipMessage = (status) => {
    if (status === 10) {
      return "Tested and verified seller";
    }
    if (status === 11) {
      return "Official Seller"
    }
    if (status === 20) {
      return "Test purchase and found Counterfeit products"
    }
    if (status === 21) {
      return "Blacklisted Seller in our system"
    }
    if (!status) {
      return "No information available"
    }
  }



  return (
    <Card
      _hover={bgItem}
      bg='transparent'
      boxShadow='unset'
      px='24px'
      py='21px'
      transition='0.2s linear'
      position='relative'
    >
      {product.has_official_seller === 1 &&
        <Triangle />}
      <Flex direction={{ base: "column" }} justify='center'>
        <Flex position='relative' align='center'>
          <Link href={product.image_link} isExternal me='15px'>
            <Image src={product.image_link} w='80px' me='16px' />
          </Link>
          <Flex
            direction='column'
            w={{ base: "70%", md: "100%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>

            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb='5px'
              fontWeight='bold'
              me='14px'
            >
              {index + 1}.&nbsp;&nbsp;
              <Link href={product.prod_link} textDecoration='underline' isExternal>

                {product.title}
              </Link>
            </Text>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                {product.keyword}
                <Image src={getPlatformIcon(product.platform_id)} width="15px" />

              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Store Name:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                {product.store_name}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Product Code
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight='bold'
              >
                {product.prod_code}
              </Text>
              <Tooltip label={hasCopied ? "Copied!" : "Copy to Clipboard"}>
                <IconButton
                  aria-label="Copy to Clipboard"
                  icon={<MdContentCopy />}
                  onClick={onCopy}
                  variant="ghost"
                  colorScheme={hasCopied ? "green" : "gray"}
                  height='17px'
                />
              </Tooltip>

            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Seller Name:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                {product.seller_name}
              </Text>
              {product.global_status &&
                <Tooltip label={toolTipMessage(product.global_status)}>
                  <IconButton
                    aria-label="Copy to Clipboard"
                    icon={(product.global_status === 20 || product.global_status === 21) ?
                      <IoMdAlert />
                      :
                      (product.global_status === 11 || product.global_status === 10) && <MdCheckCircle />}
                    variant="ghost"
                    colorScheme={(product.global_status === 21 || product.global_status === 20) ?
                      "red"
                      :
                      (product.global_status === 10 || product.global_status === 11) && "green"}
                    height='17px'
                  />
                </Tooltip>
              }
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                MRP :
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                Rs. {product.mrp}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Selling Price:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight='semibold'
                mb='5px'
                me='14px'
              >
                Rs. {product.selling_price}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Manufacturer:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                {product.manufacturer}
              </Text>
            </Flex>


            <Flex direction='row'>
              {renderStars(product.overall_rating)}
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                &nbsp;&nbsp;{product.no_of_rating}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Abuse Type:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight='semibold'
                mb='5px'
                me='14px'>
                <Select variant='flushed' w='-moz-fit-content' placeholder={chooseAbuseType()}
                  size="xs" onChange={(e) => handleAbuseType(e.target.value)} >
                  {abuseTypes?.filter(item => item.id !== product.abuse_type_id)
                    .map(item => (
                      <option value={item.id} key={item.id}>{item.abuse_type} Abuse</option>
                    ))}
                </Select>
              </Text>
            </Flex>
          </Flex>
          <Flex
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
            align='center'
            direction="column"
          >
            <Tooltip label='Official Seller'>
              <Button colorScheme='gray' fontSize="20px" p='0px' onClick={() => handleToggleWhiteList(product)}>
                <Icon color={toggleWhiteList === true ? 'green' : 'gray'} as={MdCheckCircle} />
              </Button>
            </Tooltip>
            {/* <Tooltip label='Report seller'>
              <Button colorScheme="gray" fontSize="20px" p='0px'
                onClick={() => handleToggleReport(product.keyword_id, product.platform_id, product.seller_name)}>
                <Icon color={toggleReport === true ? 'gold' : 'gray'} as={MdOutlineError} />
              </Button>
            </Tooltip> */}
            <Tooltip label='Test Purchase'>
              <Button colorScheme="gray" fontSize="20px" p='0px'
                onClick={() => handleToggleTest(product)}>
                <Icon color={toggleTest === true ? 'blue' : 'gray'} as={MdShoppingCart} />
              </Button>
            </Tooltip>
            <Tooltip label='Report Seller'>
              <Button colorScheme="gray" fontSize="20px" p='0px' onClick={() => handleToggleBlackList(product)}>
                <Icon color={toggleBlackList === true ? 'red' : 'gray'} as={MdCancel} />
              </Button>
            </Tooltip>
            <Tooltip label='Delete Product'>
              <Button colorScheme="gray" fontSize="20px" p='0px' onClick={() => handleToggleDelete(product)}>
                <Icon color={toggleDelete === true ? 'red' : 'gray'} as={MdDelete} />
              </Button>
            </Tooltip>
          </Flex>

        </Flex>

      </Flex>
    </Card>
  );
}
