// src/BrandWatchlist.js
import React, { useState, useEffect } from 'react';
import './BrandWatchlist.css'; // Import the CSS file for styling
import logo from './assets/logo.png';
import Modal from './Modal'; // Import the Modal component
import { BsPlus } from "react-icons/bs";
import 'bootstrap/dist/css/bootstrap.min.css';




const BrandWatchlist = () => {
    const [input, setInput] = useState('')
    const [entries, setEntries] = useState([]);
    const [placeholder, setPlaceholder] = useState('mamaearth shampoo');
    const [error, setError] = useState('');
    const [alerty, setAlerty] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [entrySupply, setEntrySupply] = useState([]);
    const [showPopup, setShowPopup] = useState(false);





    const addInput = () => {
        if (entries.length < 3) {
            if (!input) setError("Please Enter your brand name and product type");
            else {
                setEntries([...entries, input]);
                setInput('')
            }
        }
        else {
            entries([])
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (entries.length === 0 && input === '') {
            setError("Please add at least one brand to watchlist");
        }
        else if (entries.length < 3 && input != '') {
            setEntries([...entries, input]);
            setShowModal(true)
        } else {
            setError('You can add upto 3 products in the trial version');
            setShowModal(true)
        }
        setAlerty(true)
        setInput('');
        ; // Show the modal on submit


    };
    const placeholders = [
        'mamaearth shampoo',
        'mamaearth hair oil',
        'kapiva face wash',
        'minimalist face serum',
        'weikfield jelly'
    ];


    useEffect(() => {
        const intervalId = setInterval(() => {
            setPlaceholder((prev) => {
                const currentIndex = placeholders.indexOf(prev);
                const nextIndex = (currentIndex + 1) % placeholders.length;
                return placeholders[nextIndex];
            });
        }, 2000);
        return () => clearInterval(intervalId); // Cleanup interval on component unmount

    }, []);
    useEffect(() => {
        if (alerty) {
            setEntrySupply(entries);
            setEntries([])
            setAlerty(false);
        }
    }, [alerty])

    useEffect(() => {
        if (showPopup) { 
            setTimeout(() => {
                setShowPopup(false);
            }, 5000);
        }
    })


    useEffect(() => {
        const unloadCallback = (event) => {
            event.preventDefault();
            event.returnValue = "";
            return "";
        };

        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
    }, []);
    return (
        <>
            <div className="main-container">
                <header className="main-header">
                    <a href='https://piratesalert.com'>
                    <img src={logo} alt="Brand Logo" className="logo" /> {/* Replace with your brand logo */}
                    </a>
                </header>
                <main className="main-content">
                    <h1 className='display-1 fw-bolder'>Have I been Counterfeited?</h1>
                    <h4>Add your brand to our watchlist and get instant alerts if<span className='risk'> counterfeits</span> are found.</h4>
                    <div className="input-section">
                        <div className="entries">
                            {entries.map((inp, index) =>
                                <div key={index} className="entry">
                                    <span>
                                        {inp}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div>

                        </div>
                        <form onSubmit={ handleSubmit }>
                        <div className="inputs">
                            <input
                                type="text"
                                placeholder={placeholder}
                                value={input}
                                onChange={(e) => setInput(e.target.value)}
                                className='fs-4'
                            />
                            <div className='buttons'>
                                {entries.length < 2 && <button onClick={addInput}> <BsPlus />
                                </button>}
                            </div>
                            
                        </div>
                        {error && <span>{error}</span>}
                            <button className="submit-button" type='submit'>Get A Report</button>
                            </form>
                    </div>
                </main>
            </div>
       
            <Modal show={showModal} entries={entrySupply} showPopup={showPopup} setShowPopup={setShowPopup} onClose={() => setShowModal(false)} />
            {showPopup && (
                <div className="popup-alert">
                    <p>Thank you! We'll get back to you soon.</p>
                </div>
            )}
        </>
    );
};

export default BrandWatchlist;
