// src/App.js
import React, { useEffect } from 'react';
import BrandWatchlist from './BrandWatchlist';
import CaseStudies from './CaseStudies';
import 'bootstrap/dist/css/bootstrap.min.css';

const App = () => {
  useEffect(() => {
    // Create the Google Analytics script element
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-HG93Q9XHTB';
    script.async = true;
    document.head.appendChild(script);

    // Initialize the dataLayer and configure Google Analytics
    const inlineScript = document.createElement('script');
    inlineScript.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'G-HG93Q9XHTB');
    `;
    document.head.appendChild(inlineScript);

    // Cleanup function to remove scripts when the component unmounts
    return () => {
      document.head.removeChild(script);
      document.head.removeChild(inlineScript);
    };
  }, []);

  return (
    <div className="BApp">
      <BrandWatchlist />
      <CaseStudies />
    </div>
  );
};

export default App;
