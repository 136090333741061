// ListContext.js
import React, { createContext, useState, useContext } from 'react';

const ListContext = createContext();

export const useListContext = () => {
    return useContext(ListContext);
};
const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
};
export const ListProvider = ({ children }) => {
    const [list, setList] = useState([]);
    const [checkedPlatforms, setCheckedPlatforms] = useState([]);
    const [verificationStatus, setVerificationStatus] = useState([]);
    const [abuse_type, setAbuse_type] = useState([]);
    const [keywordsList, setKeywordsList] = useState([]);
    const currentDate = getCurrentDate();

    const [dateFilter, setDateFilter] = useState({
        startDate: '2000-08-08',
        endDate: currentDate,
    });

    return (
        <ListContext.Provider value={{ list, setList, checkedPlatforms, setCheckedPlatforms, verificationStatus, setVerificationStatus, abuse_type, setAbuse_type, dateFilter, setDateFilter, keywordsList, setKeywordsList }}>
            {children}
        </ListContext.Provider>
    );
};
