import React, { useState } from 'react';
import { Box, Flex, Heading, Image,Footer,Link } from '@chakra-ui/react';
import logo from '../../../../../BA/assets/logo.png';
import './index.css';

const FirstPage = () => {
    const [timeline, setTimeline] = useState("March 2024");

    return (
        <Box className="first-page" >
            <Flex direction="column"> 
            <Flex className="thankyou-box" ms="20mm" height="20%">
                <Heading as="h1" fontSize="80px" mt="40px" l="0">THANK YOU!</Heading>
            </Flex>
            <Flex justifyContent="center" alignItems='center' className='footer'>
                <Link href='https://piratesalert.com'>
                    https://piratesalert.com
                </Link>
                </Flex>
            </Flex>
        </Box>
    );
};

export default FirstPage;
