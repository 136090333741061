import React, { useState } from 'react';
import {
    Box,
    Heading,
    Select,
    useColorModeValue,
    FormLabel,
    Flex,
    Icon,
    Text,
    Wrap,
    WrapItem,
    Image,
    IconButton,
    Button,
    Stack,
    Alert,
    AlertIcon,
    Tooltip,
    Spinner, // Import Spinner
} from "@chakra-ui/react";
import Card from "components/card/Card";
import Dropzone from "./Dropzone";
import { MdUpload, MdClose } from "react-icons/md";
import { useListContext } from '../../../../contexts/listContext';

let username = "PirateAlert";
let password = "abc123";

const Upload = () => {
    const [brand, setBrand] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [token, setToken] = useState([]);
    const { keywordsList, setKeywordsList } = useListContext();

    const [successAlert, setSuccesAlert] = useState('');
    const [failureAlert, setFailureAlert] = useState('');
    const [loading, setLoading] = useState(false); // New state for loader

    const handleSuccessAlert = async (alertMessage) => {
        setSuccesAlert(alertMessage);
        setTimeout(() => {
            setSuccesAlert('');
        }, 5000);
    };
    const handleFailureAlert = async (alertMessage) => {
        setFailureAlert(alertMessage);
        setTimeout(() => {
            setFailureAlert('');
        }, 5000);
    };

    const handleFilesUploaded = (files) => {
        const validExtensions = ['png', 'jpeg', 'jpg'];
        const filteredFiles = files.filter((file) => {
            const extension = file.name.split('.').pop().toLowerCase();
            return validExtensions.includes(extension);
        });

        if (filteredFiles.length === 0) {
            // Handle invalid files (optional)
            handleFailureAlert('Invalid file type. Please upload only PNG, JPEG or JPG files.');
            setUploadedFiles([]);
        }
        else {
            setUploadedFiles(files);
        }
        
    };

    const handleSubmit = async () => {
        try {
            setLoading(true); // Show loader
            const formData = new FormData();
            formData.append('brand', JSON.stringify(brand));
            formData.append('username', username);
            formData.append('password', password);

            uploadedFiles.forEach((file, index) => {
                formData.append(`images`, file);
            });

            const response = await fetch('https://anticounterfeit.co.in/api/originalProducts/add-product/', {
                method: 'POST',
                body: formData,
            });

            const json = await response.json();

            if (response.status === 409) {
                handleSuccessAlert('Images already exists');
            }
            else if (response.ok) {
                handleSuccessAlert("Uploaded Successfully");
            } else if (response.status !== 200) {
                handleFailureAlert("Error uploading Images");
                // alert(json.message);
            }
        } catch (e) {
            handleFailureAlert("Error uploading Images");
        } finally {
            setLoading(false); // Hide loader
        }
    };

    const removeFile = (index) => {
        const updatedFiles = [...uploadedFiles];
        updatedFiles.splice(index, 1);
        setUploadedFiles(updatedFiles);
    };

    const handleDropdown = (e) => {
        e.preventDefault();
        const selectedKeyword = keywordsList.find((item) => item.id === parseInt(e.target.value)).brand;
        setBrand({
            id: e.target.value,
            brand: selectedKeyword,
        });
        console.log({
            id: e.target.value,
            brand: selectedKeyword,
        });
    };

    const brandColor = useColorModeValue("brand.500", "white");
    const textColor = useColorModeValue("secondaryGray.900", "white");

    return (
        <Card p="20px" align="center" direction="column" w="100%">
            <Flex
                direction={{ md: 'row', sm: "column" }}
                maxW="100%"
                background="transparent"
                borderRadius="15px"
                me="auto"
                mb={{ base: "20px", md: "auto" }}
                color={textColor}
            >
                <FormLabel mt="7px">Brand:</FormLabel>
                <Select
                    placeholder="Select a brand"
                    maxW="300px"
                    mr={{ md: "50px" }}
                    color={textColor}
                    onChange={handleDropdown}
                    value={brand.id}
                >
                    {keywordsList.map((keyword) => (
                        <option key={keyword.id} value={keyword.id}>
                            {keyword.name}
                        </option>
                    ))}
                </Select>
            </Flex>
            <Text
                fontWeight="semibold"
                color={textColor}
                fontSize="md"
                mt="20px"
                textAlign="start"
            >
                Upload Images of your product (up to 5 Images)
            </Text>
            <Dropzone
                w={{ base: "100%", "2xl": "268px" }}
                me="36px"
                maxH={{ base: "60%", lg: "60%", "2xl": "100%" }}
                minH={{ base: "60%", lg: "60%", "2xl": "100%" }}
                onFilesUploaded={handleFilesUploaded}
                content={
                    <Box>
                        <Icon as={MdUpload} w="40px" h="40px" color={brandColor} />
                        <Flex justify="center" mx="auto" mb="12px">
                            <Text fontSize="xl" fontWeight="700" color={brandColor}>
                                Upload Files
                            </Text>
                        </Flex>
                        <Text fontSize="sm" fontWeight="500" color="secondaryGray.500">
                            PNG, JPG, and JPEG files are allowed
                        </Text>
                    </Box>
                }
            />
            <Wrap spacing="30px" mt="20px">
                {uploadedFiles.map((file, index) => (
                    <WrapItem
                        key={index}
                        position="relative"
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                    >
                        <Image
                            src={URL.createObjectURL(file)}
                            alt={`uploaded ${file.name}`}
                            boxSize="100px"
                            objectFit="cover"
                            borderRadius={10}
                        />
                        <Tooltip label={file.name} hasArrow>
                            <Text
                                mt={2}
                                textAlign="center"
                                whiteSpace="nowrap"
                                overflow="hidden"
                                textOverflow="ellipsis"
                                maxWidth="200px"
                            >
                                {file.name.length > 15 ? `${file.name.slice(0, 15)}...` : file.name}
                            </Text>
                        </Tooltip>
                        <IconButton
                            size="sm"
                            icon={<MdClose />}
                            position="absolute"
                            top="5px"
                            right="5px"
                            onClick={() => removeFile(index)}
                            aria-label="Remove file"
                        />
                    </WrapItem>
                ))}
            </Wrap>
            <Button mt={4} variant="action" onClick={handleSubmit} isDisabled={!brand || loading}>
                {loading ? <Spinner size="sm" color="white" /> : "Submit"}
            </Button>
            <Stack spacing={3} position="fixed" right="10px" bottom="2vh">
                {successAlert !== '' && (
                    <Alert status="success" variant="left-accent" w="40vw">
                        <AlertIcon />
                        {successAlert}
                    </Alert>
                )}
                {failureAlert !== '' && (
                    <Alert status="error" variant="left-accent" w="40vw">
                        <AlertIcon />
                        {failureAlert}
                    </Alert>
                )}
            </Stack>
        </Card>
    );
};

export default Upload;
