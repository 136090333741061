import React from 'react';
import './SecondPage.css';
import PieCard from './PieCard';
import SuspectedTable from "./SuspectedTable";
import {
    Flex,
    Heading,
    Text,
    SimpleGrid,
    useColorModeValue,
} from "@chakra-ui/react";

const SecondPage = (props) => {
    const { topSuspectedBrands,
topSuspectedSellers,
topInfringingPlatforms } = props;

    const finalData = [
        { brand: "La shiels", count: 20 },
        { brand: "scaple pro", count: 30 },
        { brand: "glenmark", count: 20 },
        { brand: "Abc", count: 10 },
        { brand: "DEF", count: 10 },
        { brand: "GHI", count: 10 }
    ];

    const textColor = useColorModeValue("secondaryGray.900", "white");

    return (
        <div className="second-page">
            <Heading fontSize='24px' mb='10px'>
                <Flex paddingBottom='10px' justify='space-between' align='center'>
                    <Text
                        color={textColor}
                        fontSize='24px'
                        fontWeight='700'
                        lineHeight='100%'
                        m='auto'
                        className="heading1"
                    >
                        OVERVIEW
                    </Text>
                </Flex>
            </Heading>
            <Flex px='10px' paddingBottom='10px' justify='space-between' align='center'>
                <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='700'
                    lineHeight='100%'
                    m='auto'
                    className="heading2"
                >
                    Top Suspected Brands
                </Text>
            </Flex>
            <SimpleGrid columns="2" gap='10px' mb='20px'>
                <SuspectedTable finalData={topSuspectedBrands} heading="Brands" />
                <PieCard finalData={topSuspectedBrands} />
            </SimpleGrid>
            <Flex px='10px' paddingBottom='10px' justify='space-between' align='center'>
                <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='700'
                    lineHeight='100%'
                    m='auto'
                    className="heading2"
                >
                    Top Suspected Sellers
                </Text>
            </Flex>
            <SimpleGrid columns='2' gap='10px' mb='20px'>
                <SuspectedTable finalData={topSuspectedSellers} heading="Sellers" />
                <PieCard finalData={topSuspectedSellers} />
            </SimpleGrid>
            <Flex px='10px' paddingBottom='10px' justify='space-between' align='center'>
                <Text
                    color={textColor}
                    fontSize='18px'
                    fontWeight='700'
                    lineHeight='100%'
                    m='auto'
                    className="heading2"
                >
                    Top Infringing Platforms
                </Text>
            </Flex>
            <SimpleGrid columns="2" gap='10px' mb='20px'>
                <SuspectedTable finalData={topInfringingPlatforms} heading="Platforms" />
                <PieCard finalData={topInfringingPlatforms} />
            </SimpleGrid>
        </div>
    );
};

export default SecondPage;
