import React from 'react';
import ReactDOM from 'react-dom';
import 'assets/css/App.css';//HashRouter
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import AuthLayout from 'layouts/auth';
import AdminLayout from 'layouts/admin';
// import RtlLayout from 'layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from 'theme/theme';

import { AuthContextProvider } from './contexts/AuthContext'; 
import { ListProvider } from './contexts/listContext';
import { SuspiciousProductProvider } from './contexts/ProductContext';
import BrandAcquisition from './BA/BrandAcquisition';
import Report from './views/admin/default/Report';


// import { ThemeEditorProvider } from '@hypertheme-editor/chakra-ui';

ReactDOM.render(
	<AuthContextProvider>
		<ListProvider>
			<SuspiciousProductProvider>
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			{/* <ThemeEditorProvider> */}
						<Router>
							<Switch>
								<Route path={`/counterfeit-product-watchlist`} component={BrandAcquisition} />
								<Route path={`/report`} component={Report} />
						<Route path={`/auth`} component={AuthLayout} />
								<Route path={`/admin`} component={AdminLayout} />

						<Redirect from='/' to='/auth' />
					</Switch>
						</Router>
			{/* </ThemeEditorProvider> */}
		</React.StrictMode>
				</ChakraProvider>
			</SuspiciousProductProvider>
		</ListProvider>
		</AuthContextProvider>,
	document.getElementById('root')
);
