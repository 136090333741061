import React, { useEffect } from "react";
import { useState } from "react";

// Chakra imports
import {
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  Link,
  Button,
  IconButton,
  Tooltip,
  useClipboard
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { StarIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico'
import Shopsy from 'assets/img/shopsy.ico';


import { MdCheckCircle, MdCancel, MdOutlineError, MdShoppingCart, MdContentCopy, MdAlbum } from "react-icons/md";




export default function Item(props) {
  const { product, handleInsertSeller, removeFromInsert, selectAll, first, insertSeller, index } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  const textColorDate = useColorModeValue("secondaryGray.600", "white");
  const { hasCopied, onCopy } = useClipboard(product.prod_code);
  // const [toggleX, setToggleX] = useState(false);
  // const [toggleOg, setToggleOg] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [toggleTest, setToggleTest] = useState(false);





  const handleToggle = async (pd_id) => {
    setToggle(!toggle);
    if (!toggle) {
      await handleInsertSeller(pd_id);
    } else {
      await removeFromInsert(pd_id);
    }
  }

  useEffect(() => {
    setToggle(false);
  }, [product]);

  useEffect(() => {
    if (!first) {
      if (selectAll) {
        setToggle(true);
      } else {
        setToggle(false);
        // //console.log(insertSeller)

      }
    }
  }, [selectAll, first]);




  const getPlatformIcon = (platformId) => {
    switch (platformId) {
      case 1:
        return Amazon; // Use imported Amazon icon
      case 2:
        return Flipkart; // Use imported Flipkart icon
      case 3:
        return Snapdeal;// Use imported Snapdeal icon
      case 4:
        return Meesho;
      case 5:
        return Glowroad;
      case 6:
        return Shopsy;
    }
  };
  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const totalStars = filledStars + (hasHalfStar ? 1 : 0);
    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<Icon as={StarIcon} color="gold" key={`star-${i}`} />);
    }

    if (hasHalfStar) {
      stars.push(<Icon as={StarIcon} color="gold" key={`half-star`} />);
    }

    const emptyStars = 5 - totalStars;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Icon as={StarIcon} color="gray.300" key={`empty-star-${i}`} />);
    }

    return stars;
  };






  return (
    <Card
      _hover={bgItem}
      bg='transparent'
      boxShadow='unset'
      px='24px'
      py='21px'
      transition='0.2s linear'>
      <Flex direction={{ base: "column" }} justify='center'>
        <Flex position='relative' align='center'>
          <Link href={product.image_link} isExternal me='15px'>
            <Image src={product.image_link} w='80px' me='16px' />
          </Link>
          <Flex
            direction='column'
            w={{ base: "70%", md: "100%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>

            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb='5px'
              fontWeight='bold'
              me='14px'
            >
              {index + 1}.&nbsp;&nbsp;
              <Link href={product.prod_link} textDecoration='underline' isExternal>
                {product.title}
              </Link>
            </Text>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                {product.keyword}
                <Image src={getPlatformIcon(product.platform_id)} alt="platform icon" width="15px" />

              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                MRP :
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                Rs. {product.mrp}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Product Code
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight='bold'
              >
                {product.prod_code}
              </Text>
              <Tooltip label={hasCopied ? "Copied!" : "Copy to Clipboard"}>
                <IconButton
                  aria-label="Copy to Clipboard"
                  icon={<MdContentCopy />}
                  onClick={onCopy}
                  variant="ghost"
                  colorScheme={hasCopied ? "green" : "gray"}
                  height='17px'
                />
              </Tooltip>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Verification Status:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight='semibold'
                mb='5px'
                me='14px'
              >
                &nbsp;&nbsp; {product.is_text}
              </Text>
            </Flex>
            <Flex direction='row'>
              {renderStars(product.overall_rating)}
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                &nbsp;&nbsp;{product.no_of_rating}
              </Text>
            </Flex>
          </Flex>
          <Flex
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
            align='center' direction="column">
            <Tooltip label='Remove'>
              <Button colorScheme='gray' fontSize="20px" p='0px' onClick={() => handleToggle(product.pd_id)}>
                <Icon color={toggle === true ? 'green' : 'gray'} as={MdAlbum} />
              </Button>
            </Tooltip>
            {/* <Tooltip label='Mark as Verified'>
              <Button colorScheme='gray' fontSize="20px" p='0px' onClick={() => handleToggleOg(product.pd_id)}>
                <Icon color={toggleOg === true ? 'green' : 'gray'} as={MdCheckCircle} />
              </Button>
            </Tooltip>
            <Tooltip label='Test Purchase'>
              <Button colorScheme="gray" fontSize="20px" p='0px'
                onClick={() => handleToggleTest(product.keyword_id, product.platform_id, product.seller_name)}>
                <Icon color={toggleTest === true ? 'blue' : 'gray'} as={MdShoppingCart} />
              </Button>
            </Tooltip> */}

          </Flex>

        </Flex>

      </Flex>
    </Card>
  );
}
