import React, { useEffect, useState } from "react";
import { Box, Button, SimpleGrid } from "@chakra-ui/react";
import NFT from "./NFT";

const Brands = () => {
    const [nfts, setNfts] = useState([]); // All NFTs data
    const [visibleNfts, setVisibleNfts] = useState([]); // NFTs visible on the screen
    const [loading, setLoading] = useState(false); // Track loading state
    const [itemsToShow, setItemsToShow] = useState(20); // Number of items to show initially and on click

    // Function to fetch all NFTs at once
    const fetchAllBrands = async () => {
        try {
            setLoading(true); // Set loading state to true

            const userInfo = JSON.parse(localStorage.getItem("PAuser"));
            const uid = userInfo?.message?.result?.id;

            const response = await fetch("https://anticounterfeit.co.in/api/general/getbrands", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uid,
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setNfts(data); // Store all data in state
            setVisibleNfts(data.slice(0, itemsToShow)); // Show initial items
        } catch (error) {
            console.error("Error fetching brands:", error);
        } finally {
            setLoading(false); // Set loading state to false
        }
    };

    // Function to load more items
    const loadMore = () => {
        setItemsToShow((prevItemsToShow) => prevItemsToShow + 20);
    };

    // Update visible NFTs when itemsToShow changes
    useEffect(() => {
        setVisibleNfts(nfts.slice(0, itemsToShow));
    }, [itemsToShow, nfts]);

    // Fetch all NFTs once on mount
    useEffect(() => {
        fetchAllBrands();
    }, []);

    return (
        <Box textAlign="center">
            <SimpleGrid columns={{ base: 2, md: 3, lg: 4, "2xl": 8 }} gap="20px" mb="20px">
                {visibleNfts.map((nft, index) => (
                    <NFT
                        key={index}
                        name={nft.brand_name} // Assuming 'brand_name' is a field in the API response
                        image={nft.image_link} // Assuming 'image_link' is a field in the API response
                        download={`/admin/brands?brand=${encodeURIComponent(nft.brand_name)}`} // Properly encoding the brand name
                    />
                ))}
            </SimpleGrid>
            {visibleNfts.length < nfts.length && !loading && (
                <Button onClick={loadMore} mt="20px" mb="20px">
                    Load More
                </Button>
            )}
            {loading && (
                <Box textAlign="center" width="100%" mt="20px">
                    Loading more NFTs...
                </Box>
            )}
        </Box>
    );
};

export default Brands;
