import React from 'react';
import {
    Box,
    Heading,
    TabPanels,
    TabList,
    Tabs,
    Tab,
    TabPanel
} from "@chakra-ui/react";
import Upload from "./components/Upload";
import OriginalProducts from './components/OriginalProducts';

const index = () => {
    return (
        <Tabs colorScheme='messenger' pt={{ base: "180px", md: "80px", xl: "80px" }}>
            <TabList>
                <Tab border='transparent'>Upload Original Product</Tab>
                <Tab border='transparent'>Orginal Products</Tab>

            </TabList>
            <TabPanels>
                <TabPanel>
                    <Box>
                        <Upload />
                    </Box>
                </TabPanel>
                <TabPanel>
                    <OriginalProducts />
                </TabPanel>
            </TabPanels>
        
        </Tabs>
    )
}

export default index