import React from "react";
import { useState } from "react";

// Chakra imports
import {
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  Link,
  Button,
  IconButton,
  Tooltip,
  useClipboard, Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { StarIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico';
import Shopsy from 'assets/img/shopsy.ico';


import { MdCheckCircle, MdCancel, MdOutlineError, MdCommentsDisabled, MdShoppingCart, MdContentCopy, MdAlbum } from "react-icons/md";




export default function Item(props) {
  const { product, handleUpdateProduct, removeFromUpdate, handleInsertSeller, removeFromInsert, index, handleInsertAbuse } = props;
  // Chakra Color Mode
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  const textColorDate = useColorModeValue("secondaryGray.600", "white");
  const { hasCopied, onCopy } = useClipboard(product.prod_code);
  // const [toggleReport, setToggleReport] = useState(false);
  const [toggleTest, setToggleTest] = useState(false);
  const [toggleGenuine, setToggleGenuine] = useState(false);
  const [toggleOrderPlaced, setToggleOrderPlaced] = useState(false);
  const [toggleNotReceived, setToggleNotReceived] = useState(false);
  const [toggleCounterfeit, setToggleCounterfeit] = useState(false);


  const handleToggleGenuine = async (product) => {
    setToggleGenuine(!toggleGenuine);
    setToggleNotReceived(false);
    setToggleOrderPlaced(false);
    setToggleCounterfeit(false);
    if (!toggleGenuine) {
      await handleUpdateProduct(product, 3);
      await handleInsertSeller(product, 'TW');
      await handleInsertAbuse(product, '0')

    } else {
      await removeFromUpdate(product, 3);
      await removeFromInsert(product, 'TW');
      await handleInsertAbuse(product, '0')

    }
  }
  const handleNotReceived = async (product) => {
    setToggleGenuine(false);
    setToggleNotReceived(!toggleNotReceived);
    setToggleOrderPlaced(false);
    setToggleCounterfeit(false);
    if (!toggleNotReceived) {
      await handleUpdateProduct(product, 2);
      // await handleInsertSeller(product, 'TC');

    } else {
      await removeFromUpdate(product, 2);
      // await removeFromInsert(product, 'TC');
    }
  }
  // const handleToggleTest = async (keyword_id, platform_id, seller_name) => {
  //   setToggleWhiteList(false);
  //   setToggleTest(!toggleTest);
  //   setToggleBlackList(false);
  //   if (!toggleTest) {
  //     await handleInsertSeller(keyword_id, platform_id, seller_name, 'T');
  //   } else {
  //     await removeFromInsert(keyword_id, platform_id, seller_name, 'T');
  //   }
  // }

  const handleToggleOrderPlaced = async (psm_id) => {
    setToggleOrderPlaced(!toggleOrderPlaced);
    if (!toggleOrderPlaced) {
      await handleUpdateProduct(product, 1);
    } else {
      await removeFromUpdate(product, 1);
    }
  }
  const handleToggleCounterfeit = async (product) => {
    setToggleGenuine(false);
    setToggleNotReceived(false);
    setToggleOrderPlaced(false);
    setToggleCounterfeit(!toggleCounterfeit);
    if (!toggleCounterfeit) {
      await handleInsertSeller(product, 'TC');
      await handleUpdateProduct(product, 4);
      await handleInsertAbuse(product, 6);


    } else {
      await removeFromInsert(product, 'TC');
      await removeFromUpdate(product, 4);
    }
  }


  const getPlatformIcon = (platformId) => {
    switch (platformId) {
      case 1:
        return Amazon; // Use imported Amazon icon
      case 2:
        return Flipkart; // Use imported Flipkart icon
      case 3:
        return Snapdeal;
      case 4:
        return Meesho;// Use imported Snapdeal icon
      case 5:
        return Glowroad;
      case 6:
        return Shopsy 
    }
  };
  const renderStars = (rating) => {
    const filledStars = Math.floor(rating);
    const hasHalfStar = rating % 1 >= 0.5;
    const totalStars = filledStars + (hasHalfStar ? 1 : 0);
    const stars = [];

    for (let i = 0; i < filledStars; i++) {
      stars.push(<Icon as={StarIcon} color="gold" key={`star-${i}`} />);
    }

    if (hasHalfStar) {
      stars.push(<Icon as={StarIcon} color="gold" key={`half-star`} />);
    }

    const emptyStars = 5 - totalStars;
    for (let i = 0; i < emptyStars; i++) {
      stars.push(<Icon as={StarIcon} color="gray.300" key={`empty-star-${i}`} />);
    }

    return stars;
  };
  return (
    <Card
      _hover={bgItem}
      bg='transparent'
      boxShadow='unset'
      px='24px'
      py='21px'
      transition='0.2s linear'>
      <Flex direction={{ base: "column" }} justify='center'>
        <Flex position='relative' align='center'>
          <Link href={product.image_link} isExternal me='15px'>
            <Image src={product.image_link} w='80px' me='16px' />
          </Link>
          <Flex
            direction='column'
            w={{ base: "70%", md: "100%" }}
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}>

            <Text
              color={textColor}
              fontSize={{
                base: "md",
              }}
              mb='5px'
              fontWeight='bold'
              me='14px'
            >
              {index + 1}.&nbsp;&nbsp;
              <Link href={product.prod_link} textDecoration='underline' isExternal>
                {product.title}
              </Link>
            </Text>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                {product.keyword}
                <Image src={getPlatformIcon(product.platform_id)} width="15px" />

              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Store Name:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                {product.store_name}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Product Code
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight='bold'
              >
                {product.prod_code}
              </Text>
              <Tooltip label={hasCopied ? "Copied!" : "Copy to Clipboard"}>
                <IconButton
                  aria-label="Copy to Clipboard"
                  icon={<MdContentCopy />}
                  onClick={onCopy}
                  variant="ghost"
                  colorScheme={hasCopied ? "green" : "gray"}
                  height='17px'
                />
              </Tooltip>

            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Seller Name:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                {product.seller_name}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                MRP :
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                Rs. {product.mrp}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Selling Price:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                fontWeight='semibold'
                mb='5px'
                me='14px'
              >
                Rs. {product.selling_price}
              </Text>
            </Flex>
            <Flex direction='row'>
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                Manufacturer:
              </Text>
              <Text
                color={textColor}
                fontSize={{
                  base: "sm",
                }}
                mb='5px'
                fontWeight='bold'
                me='14px'
              >
                {product.manufacturer}
              </Text>
            </Flex>

            <Flex direction='row'>
              {renderStars(product.overall_rating)}
              <Text
                color='secondaryGray.600'
                fontSize={{
                  base: "sm",
                }}
                fontWeight='400'
                me='14px'>
                &nbsp;&nbsp;{product.no_of_rating}
              </Text>
            </Flex>
          </Flex>
          <Flex
            me={{ base: "4px", md: "32px", xl: "10px", "3xl": "32px" }}
            align='center' direction="column">
            {product.test_purchase_status === null &&
              <Tooltip label='Order Placed'>
                <Button colorScheme='gray' fontSize="20px" p='0px' onClick={() => handleToggleOrderPlaced(product.psm_id, product.test_purchase_status)}>
                  <Icon color={toggleOrderPlaced === true || product.test_purchase_status > 0 ? 'green' : 'gray'} as={MdAlbum} />
                </Button>
              </Tooltip>
            }
            {/* <Tooltip label='Report seller'>
              <Button colorScheme="gray" fontSize="20px" p='0px'
                onClick={() => handleToggleReport(product.keyword_id, product.platform_id, product.seller_name)}>
                <Icon color={toggleReport === true ? 'gold' : 'gray'} as={MdOutlineError} />
              </Button>
            </Tooltip> */}
            {product.test_purchase_status !== null &&
              <>
                <Tooltip label='Order Not Received'>
                  <Button colorScheme="gray" fontSize="20px" p='0px'
                    onClick={() => handleNotReceived(product)}>
                    <Icon color={toggleNotReceived === true || product.test_purchase_status === 2 ? 'green' : 'gray'} as={MdOutlineError} />
                  </Button>
                </Tooltip>
                <Tooltip label='Genuine Product'>
                  <Button colorScheme="gray" fontSize="20px" p='0px'
                    onClick={() => handleToggleGenuine(product)}>
                    <Icon color={toggleGenuine === true || product.test_purchase_status === 2 ? 'green' : 'gray'} as={MdCheckCircle} />
                  </Button>
                </Tooltip>
                <Tooltip label='Counterfeit product'>
                  <Button colorScheme="gray" fontSize="20px" p='0px' onClick={() => handleToggleCounterfeit(product)}>
                    <Icon color={toggleCounterfeit === true ? 'red' : 'gray'} as={MdCancel} />
                  </Button>
                </Tooltip>
              </>
            }
          </Flex>

        </Flex>

      </Flex>
    </Card>
  );
}
