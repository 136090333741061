import React, { useState } from "react";
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Grid, GridItem,
  FormControl,
  Input,
  Text,
  Image,
  RadioGroup,
  Radio,
  Wrap,
  FormHelperText,
  WrapItem,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Heading,
} from "@chakra-ui/react";
import IconBox from "components/icons/IconBox";
import Card from "components/card/Card";
import Dropzone from "./components/Dropzone";
import { MdUpload } from "react-icons/md";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Result from "./Result";
import Tile from "./components/Tile";



export default function UserReports() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [brand, setBrand] = useState();
  const [category, setCategory] = useState();
  const cropperRef = React.useRef(null);
  const [showResult, setShowResult] = useState(false);
  const [result, setResult] = useState([]);
  const [status, setStatus] = useState(0);
  const [secondResult, setSecondResult] = useState([]);
  // const { activeStep, setActiveStep } = useSteps({
  //   index: 1,
  //   count: steps.length,
  // })

  const handleFilesUploaded = (files) => {
    //console.log('Uploaded files:', files);
   
    setUploadedFiles(files);
    setSelectedFile(files[0]);
  };

  const handleCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    setCroppedImage(cropper.getCroppedCanvas().toDataURL());
    onClose();
  };


 const handleSubmit = async () => {
    setShowResult(true);
    setResult([]);
    setSecondResult([]);
    setStatus(1);

    const formData = new FormData();
    const formData1 = new FormData();

    formData.append('keyword', brand);  // Assuming 'brand' is the keyword you want to send
    uploadedFiles.forEach((file, index) => {
      formData.append('files', file);
    });

    if (croppedImage) {
      const blob = await fetch(croppedImage).then((r) => r.blob());
      formData1.append('keyword', brand);
      formData1.append('cropped_file', blob, 'croppedImage.png');
    }

    try {
      const fetchExtractFeatures = fetch('https://lookalike.anticounterfeit.co.in/extract_features', {
        method: 'POST',
        body: formData,
      });

      const fetchScrape = fetch('https://lookalike.anticounterfeit.co.in/scrape', {
        method: 'POST',
        body: formData1,
      });

      fetchExtractFeatures.then(response => {
        if (response.ok) {
          setStatus(3);
        }
        // setActiveStep({ index: 2 })
      }).catch(error => {
        console.error('Fetch Error (extract_features):', error);
        alert("Something went wrong. please try again");
        setShowResult(false);

      });

      fetchScrape.then(response1 => {
        if (response1.ok) {
          setStatus(2);
          // setActiveStep({ index: 2 })

        }
      }).catch(error => {
        console.error('Fetch Error (scrape):', error);
        alert("Something went wrong. please try again");
        setShowResult(false);
      });

      const response = await fetchExtractFeatures;
      const response1 = await fetchScrape;

      if (response.ok && response1.ok) {
        setStatus(4);
        // setActiveStep({ index: 3 })
        const result = await response.json();
        const result1 = await response1.json();
        //console.log('API Response:', result, result1);

        const response2 = await fetch('https://lookalike.anticounterfeit.co.in/query_images', {  // Update to your actual API endpoint
          method: 'POST',
          body: JSON.stringify({ keyword: brand }),
          headers: {
            'Content-Type': 'application/json'
          }
        });

        if (response2.ok) {
          const result2 = await response2.json();
          //console.log('API Response:', result2);
          setStatus(5)
          setResult(result2.results);
        } else {
          console.error('API Error:', response2.statusText);
          alert("Something went wrong. please try again");
          setShowResult(false);

        }
      } else {
        console.error('API Error:', response.statusText, response1.statusText);
        alert("Something went wrong. please try again");
        setShowResult(false);
      }

      const response3 = await fetch('https://lookalike.anticounterfeit.co.in/extract_images', {  // Update to your actual API endpoint
        method: 'POST',
        body: formData,

      });
      if (response3.ok) {
        const result3 = await response3.json();
        //console.log('API Response:', result3);
        setSecondResult(result3?.results);
        setShowResult(true)
      }

    } catch (error) {
      console.error('Fetch Error:', error);
    }
  };



  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>

      {!showResult ? <>
        <Card p='20px' align='center' direction='column' w='100%'>
          <FormControl isRequired>
            <Flex
              direction={{ md: 'row', sm: "column" }}
              maxW='100%'
              background='transparent'
              borderRadius='15px'
              mx={{ base: "auto", lg: "unset" }}
              me='auto'
              mb={{ base: "20px", md: "auto" }}
              color={textColor}
            >
              <FormLabel mt='7px' >Brand:</FormLabel>
              <Input placeholder='eg. mamaearth' maxW='300px' mr={{ md: "50px" }} color={textColor} onChange={(e) => setBrand(e.target.value)} />
              <FormLabel mt='7px'>Category:</FormLabel>
              <Input placeholder='eg. Shampoo or Face wash' color={textColor} maxW='300px' onChange={(e) => setCategory(e.target.value)} />
            </Flex>
            <Text
              fontWeight='semibold'
              color={textColor}
              fontSize='md'
              mt='20px'
              textAlign='start'>
              Upload Images of your product (upto 5 Images)
            </Text>
            <Dropzone
              w={{ base: "100%", "2xl": "268px" }}
              me='36px'
              maxH={{ base: "60%", lg: "60%", "2xl": "100%" }}
              minH={{ base: "60%", lg: "60%", "2xl": "100%" }}
              onFilesUploaded={handleFilesUploaded}
              content={
                <Box>
                  <Icon as={MdUpload} w='40px' h='40px' color={brandColor} />
                  <Flex justify='center' mx='auto' mb='12px' >
                    <Text fontSize='xl' fontWeight='700' color={brandColor}>
                      Upload Files
                    </Text>
                  </Flex>
                  <Text fontSize='sm' fontWeight='500' color='secondaryGray.500'>
                    PNG, JPG and JPEG files are allowed
                  </Text>
                </Box>
              }
            />
            {uploadedFiles.length > 0 && (
              <Box mt={4}>
                <FormLabel as='legend'>Uploaded Images</FormLabel>
                <RadioGroup defaultValue={uploadedFiles[0].name}>
                  <Wrap spacing="30px">
                    {uploadedFiles.map((file, index) => (
                      <WrapItem key={index}>
                        <Radio value={file.name} onChange={() => setSelectedFile(file)}>
                          <Image
                            src={URL.createObjectURL(file)}
                            alt={`uploaded ${file.name}`}
                            boxSize="100px"
                            objectFit="cover"
                            mr={2}
                            mb={2}
                            borderRadius={10}
                          />
                          {file.name}
                        </Radio>
                      </WrapItem>
                    ))}
                  </Wrap>
                </RadioGroup>
                <FormHelperText textAlign='start'>Select Image you want Crop & Check Similarity</FormHelperText>

                {croppedImage && (
                  <Box mt={4}>
                    <Text fontWeight="bold" align="start">Cropped Image:</Text>
                    <Image src={croppedImage} alt="Cropped" boxSize="200px" borderRadius={10} align="start" />
                  </Box>
                )}

              </Box>
            )}
          </FormControl>
          <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Crop Image</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                {selectedFile && (
                  <Cropper
                    src={URL.createObjectURL(selectedFile)}
                    style={{ height: 400, width: "100%" }}
                    initialAspectRatio={1}
                    guides={false}
                    ref={cropperRef}
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button variant="action" mr={3} onClick={handleCrop}>
                  Save
                </Button>
                <Button onClick={onClose}>Cancel</Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <Flex direction='row'>
            <Button mt={4} variant="action" mr='10px' onClick={onOpen} isDisabled={!selectedFile}>
              Crop Image
            </Button>
            <Button mt={4} variant="action" onClick={handleSubmit} isDisabled={!brand && !category}>
              Submit
            </Button>
          </Flex>
        </Card>
      </>
        : <>

          <Result
            result={result}
            setShowResult={setShowResult}
            status={status}
            setStatus={setStatus}
            secondResult={secondResult}

          />
        </>
      }
    </Box>
  );
}
