import React, { useState, useEffect } from 'react'
import {
    Box,
    Heading, Button, SimpleGrid,Text
} from "@chakra-ui/react";
import Card from "components/card/Card";
import { useListContext } from '../../../../contexts/listContext';
import NFT from "./NFT";


const OriginalProducts = () => {
    const { list } = useListContext();

    const [nfts, setNfts] = useState([]); // All NFTs data
    const [sortedImages, setSortedImages] = useState([]);
    const [visibleNfts, setVisibleNfts] = useState([]); // NFTs visible on the screen
    const [loading, setLoading] = useState(false); // Track loading state
    const [itemsToShow, setItemsToShow] = useState(20); // Number of items to show initially and on click

    // Function to fetch all NFTs at once
    const fetchAllImages = async () => {
        try {

            setLoading(true); // Set loading state to true
            const userInfo = JSON.parse(localStorage.getItem("PAuser"));
            const uid = userInfo?.message?.result?.id;

            const response = await fetch("https://anticounterfeit.co.in/api/originalProducts/", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    uid
                }),
            });

            if (!response.ok) {
                throw new Error("Network response was not ok");
            }

            const data = await response.json();
            setNfts(data); // Store all data in state
            setVisibleNfts(data.slice(0, itemsToShow)); // Show initial items
        } catch (error) {
            console.error("Error fetching brands:", error);
        } finally {
            setLoading(false); // Set loading state to false
        }
    };

    // Fuction to load more items
    const loadMore = () => {
        setItemsToShow((prevItemsToShow) => prevItemsToShow + 20);
    };

    const handleFilter = async () => {
        try {
            let keywordNames = list.map((item) => item.name?.toLowerCase());
   


            let dateSort;
            if (keywordNames.length !== 0) {
                kfilter = nfts.filter((item) =>
                    keywordNames?.includes(item.brand_name.toLowerCase())
                )
            } else {
                kfilter = nfts;
            } 
            setSortedImages(kfilter);


        } catch (err) {
            //console.log('error in handleFilter', err)
        }
    }
   

    // Update visible NFTs when itemsToShow changes
    useEffect(() => {
        if (sortedImages.length > 0) {
            setVisibleNfts(sortedImages?.slice(0, itemsToShow));
        }
    }, [itemsToShow, nfts,sortedImages]);

    // Fetch all NFTs once on mount
    useEffect(() => {
        fetchAllImages();
    }, []);
    useEffect(() => {
        handleFilter();
    }, [list]);
    return (
        <Box textAlign="center">
            {(nfts.length > 0) ? <>
                <SimpleGrid columns={{ base: 2, md: 3, lg: 4, "2xl": 8 }} gap="20px" mb="20px">

                    {visibleNfts.map((nft, index) => (
                        <NFT
                            key={index}
                            name={nft.brand_name} // Assuming 'brand_name' is a field in the API response
                            image={nft.digital_ocean_link} // Assuming 'image_link' is a field in the API response
                            download={nft.digital_ocean_link} // Properly encoding the brand name
                        />
                    ))}
                </SimpleGrid>

                {visibleNfts.length < nfts.length && !loading && (
                    <Button onClick={loadMore} mt="20px" mb="20px">
                        Load More
                    </Button>
                )}
                {loading && (
                    <Box textAlign="center" width="100%" mt="20px">
                        Loading more NFTs...
                    </Box>
                )}
            </>
                :
                <Text>
                    NO IMAGES FOUND
                </Text>
            }
        </Box>
    );
}

export default OriginalProducts