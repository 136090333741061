import { useAuthContext } from './useAuthContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export const useLogin = () => {

    const { dispatch } = useAuthContext();
    const history = useHistory(); // Get the history object


    const login = async (email, password) => {
        try {
            const response = await fetch('https://anticounterfeit.co.in/api/user/login/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ user_email: email, user_password: password })
            });
            //console.log(email, password)
            const json = await response.json();

            if (response.ok) {
                //  save user to local storage
                localStorage.setItem('PAuser', JSON.stringify(json));

                //  update auth context
                dispatch({ type: 'LOGIN', payload: json });
                history.push('/admin/dashboard');
            } else if (response.status === 400) {
                //console.log(json);
                alert("Error Logging in")

            }
        } catch (e) {
            //console.log("error in login", e);
        }
    }
    return { login };
}