import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  Flex,
  Input,
  Button,
  useColorModeValue,
  Alert,
  Stack,
  AlertIcon,
} from '@chakra-ui/react';

const Dropzone = ({ content, onFilesUploaded, ...rest }) => {
  const [successAlert, setSuccessAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const handleSuccessAlert = (alertMessage) => {
    setSuccessAlert(alertMessage);
    setTimeout(() => {
      setSuccessAlert('');
    }, 5000);
  };

  const handleFailureAlert = (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: '.xlsx',
    maxFiles: 1,
    onDropRejected: (fileRejections) => {
      fileRejections.forEach((rejection) => {
        rejection.errors.forEach((error) => {
          handleFailureAlert(error.message);
        });
      });
    },
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        handleSuccessAlert('File successfully uploaded.');
        onFilesUploaded(acceptedFiles);
      }
    },
  });

  const bg = useColorModeValue('gray.100', 'navy.700');
  const borderColor = useColorModeValue('secondaryGray.100', 'whiteAlpha.100');

  return (
    <Flex
      align="center"
      justify="center"
      bg={bg}
      border="1px dashed"
      borderColor={borderColor}
      borderRadius="16px"
      w="100%"
      h="20vh"
      cursor="pointer"
      {...getRootProps({ className: 'dropzone' })}
      {...rest}
    >
      <Input variant="main" {...getInputProps()} />
      <Button variant="no-effects">{content}</Button>
      <Stack spacing={3} position="fixed" right="10px" bottom="2vh">
        {successAlert !== '' && (
          <Alert status="success" variant="left-accent" w="40vw">
            <AlertIcon />
            {successAlert}
          </Alert>
        )}
        {failureAlert !== '' && (
          <Alert status="error" variant="left-accent" w="40vw">
            <AlertIcon />
            {failureAlert}
          </Alert>
        )}
      </Stack>
    </Flex>
  );
};

export default Dropzone;
