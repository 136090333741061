import {
    Flex,
    Table,
    Checkbox,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
} from "@chakra-ui/react";
import React, { useMemo, useState, useEffect } from "react";
import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
export default function SuspectedBrandsTable(props) {
    const { fetchedProducts, finalData, setFinalData } = props;
    // finalData, setFinalData
    const userInfo = JSON.parse(localStorage.getItem('PAuser'));
    const textColor = useColorModeValue("secondaryGray.900", "white");
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
    // const [fetchedProducts, setFetchedProducts] = useState([]);

    return (
        <Card
            direction='column'
            w='100%'
            px='10px'
            overflowX={{ sm: "scroll", lg: "hidden" }}
        >
            <Flex px='25px' paddingBottom='10px' justify='space-between' align='center'>
                <Text
                    color={textColor}
                    fontSize='22px'
                    fontWeight='700'
                    lineHeight='100%'
                    m='auto'
                >
                    Platform Analysis
                </Text>
            </Flex>
            <Table variant='simple' color='gray.500' mb='24px'>
                <Thead>
                    <Tr >                 
                        <Th
                            pe='10px'
                            borderColor={borderColor}>
                            <Flex
                                justify='space-between'
                                align='center'
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color='gray.400'>
                                Platform
                            </Flex>
                        </Th>
                        <Th
                            pe='10px'
                            borderTop='4px solid #6ce5e8'
                            margin='5px'
                            marginTop='5px'
                            marginRight='5px'
                            marginLeft='5px'>
                            <Flex
                                justify='space-between'
                                align='center'
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color='gray.400'>
                                Suspicious
                            </Flex>
                        </Th>
                        <Th
                            pe='10px'
                            borderTop='4px solid #41b8d5'
                            margin='5px'
                            marginTop='5px'
                            marginRight='5px'
                            marginLeft='5px'>
                            <Flex
                                justify='space-between'
                                align='center'
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color='gray.400'>
                                Reported
                            </Flex>
                        </Th>
                        <Th
                            pe='10px'
                            borderTop='4px solid #2d8bba'
                            margin='5px'
                            marginTop='5px'
                            marginRight='5px'
                            marginLeft='5px'>
                            <Flex
                                justify='space-between'
                                align='center'
                                fontSize={{ sm: "10px", lg: "12px" }}
                                color='gray.400'>
                                Removed
                            </Flex>
                        </Th>

                    </Tr>
                </Thead>
                <Tbody >
                    {finalData?.map((item, index) => (
                        <Tr key={index}>
                         
                            <Td
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                                {item.Platforms}
                            </Td>
                            <Td
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                                {item.suspicious}
                            </Td>
                            <Td
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                                {item.reported}
                            </Td>
                            <Td
                                fontSize={{ sm: "14px" }}
                                minW={{ sm: "150px", md: "200px", lg: "auto" }}
                                borderColor='transparent'>
                                {item.removed}
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </Card>
    );
}
