import React, { useState, useRef } from 'react';
import Slider from 'react-slick';
import { BsArrowLeftCircle, BsArrowRightCircle } from 'react-icons/bs';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './CaseStudies.css'; // Import CSS for styling
import th1 from './assets/case_studies/3.png';
import th2 from './assets/case_studies/4.png';
import th3 from './assets/case_studies/28.png';
import th4 from './assets/case_studies/1.png';
import th5 from './assets/case_studies/2.png';

const caseStudies = [
    {
        title: 'Wellcore Fake Product',
        embedUrl: 'https://www.canva.com/design/DAGNc-RIDqI/Kd8Wg3EuXVEKJmvwVMPswg/view?embed',
        thumbnailUrl: th1,
    },
    {
        title: 'BeBodyWash Fake Product',
        embedUrl: 'https://www.canva.com/design/DAGNctmmOGk/E9vaRDwOVHWCywWKV82Q-w/view?embed',
        thumbnailUrl: th2,
    },
    {
        title: 'Traya Fake Product',
        embedUrl: 'https://www.canva.com/design/DAGNb4iLqfo/pK7aD6moc7iLLeaJPi4PzQ/view?embed',
        thumbnailUrl: th3,
    },
    {
        title: 'The Derma co Fake Product',
        embedUrl: 'https://www.canva.com/design/DAGNbqUMHms/Tzy4ekcIwZVatmFduHZiLg/view?embed',
        thumbnailUrl: th4,
    },
    {
        title: 'Minimalist Fake Product',
        embedUrl: 'https://www.canva.com/design/DAGNbv-TOfM/QZ42dLy51xcesVwTRUZY6g/view?embed',
        thumbnailUrl: th5,
    },
];

const CaseStudies = () => {
    const [selectedEmbedUrl, setSelectedEmbedUrl] = useState(null);
    const sliderRef = useRef(null);

    const handleThumbnailClick = (embedUrl) => {
        setSelectedEmbedUrl(embedUrl);
    };

    const handleCloseModal = () => {
        setSelectedEmbedUrl(null);
    };

    function SampleNextArrow(props) {
        const { className, style, onClick } = props;
        return (
             <button className="slider-btn prev-btn position-absolute top-50 start-0 translate-middle-y" onClick={() => sliderRef.current.slickPrev()}>
                    <BsArrowLeftCircle />
                </button>
               
        );
    }

    function SamplePrevArrow(props) {
        const { className, style, onClick } = props;
        return (
            <button className="slider-btn next-btn position-absolute top-50 end-0 translate-middle-y" onClick={() => sliderRef.current.slickNext()}>
                <BsArrowRightCircle />
            </button>
        );
    }

    const settings = {
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        centerMode: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        centerPadding: '50px',
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerMode: true,
                    centerPadding: '7%',
                    dots: false
                }
            }
        ]
    };

    return (
        <div className='container case-studies-container'>
            <h2 className="my-4">Counterfeit Cases</h2>
            <div className="slider-container position-relative">
                <Slider ref={sliderRef} {...settings}>
                    {caseStudies.map((study, index) => (
                        <div key={index} className='slide'>
                            <div
                                className="thumbnail"
                                onClick={() => handleThumbnailClick(study.embedUrl)}
                            >
                                <img src={study.thumbnailUrl} alt={study.title} className="img-fluid" />
                            </div>
                            <button className='view-more-button' onClick={() => handleThumbnailClick(study.embedUrl)}>View</button>
                        </div>
                    ))}
                </Slider>
              
            </div>

            {selectedEmbedUrl && (
                <div className="modal-overlay-canva" onClick={handleCloseModal}>
                    <div className="modal-content-canva" onClick={(e) => e.stopPropagation()}>
                        <iframe
                            loading="lazy"
                            style={{ width: '100%', height: '100%' }}
                            src={selectedEmbedUrl}
                            allowFullScreen
                            title="Preview"
                        ></iframe>
                        <button className="modal-close" onClick={handleCloseModal}>
                            &times;
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default CaseStudies;
