import React, { useEffect } from "react";
import { useState } from "react";

// Chakra imports
import {
    Flex,
    Icon,
    Image,
    Text,
    useColorModeValue,
    Link,
    Button,
    IconButton,
    Tooltip,
    useClipboard,
    Input,
    Select
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
// Assets
import { StarIcon, TriangleUpIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico'

import { MdCheckCircle, MdCancel, MdOutlineError, MdShoppingCart, MdContentCopy, MdDelete } from "react-icons/md";
import { useListContext } from '../contexts/listContext';





const EditListing = (props) => {
    const textColor = useColorModeValue("brands.900", "white");
    const { psm_id, mrp, seller_name, store_name, selling_price, prod_code, setEditMode, save, setSave, fetchProducts, keyword, keywordOptions, pd_id } = props
    const { hasCopied, onCopy } = useClipboard(prod_code);
    const { list, checkedPlatforms } = useListContext();
    // const [keywordOptions, setKeywordOptions] = useState();

    const [details, setDetails] = useState({
        psm_id: psm_id,
        keyword_id: '',
        mrp: mrp,
        selling_price: selling_price,
        seller_name: seller_name,
        store_name: store_name
    });

    useEffect(async () => {
        if (save) {
            //console.log(details);
            await updateListing(details);
            fetchProducts();
        }
    }, [save]);



    const updateListing = async (details) => {
        const response = await fetch('https://anticounterfeit.co.in/api/products/updateListing', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                psm_id: details.psm_id,
                pd_id: pd_id,
                keyword_id: details.keyword_id,
                mrp: details.mrp,
                seller_name: details.seller_name,
                selling_price: details.selling_price
            })
        });
        const json = await response.json();
        if (response.ok) {
            //console.log('Edits saved ')
            setSave(false);
            setEditMode(false);
        } else if (response.status === 400) {
            //console.log(json);
            // alert(json.message);

        }
    }




    return (
        <>
            <Flex direction='row'>
                <Select variant='flushed' w='-moz-fit-content' placeholder={keyword}
                    onChange={(e) => setDetails(prev => ({ ...prev, keyword_id: e.target.value }))} size="xs">
                    {keywordOptions.map((item) => (
                        <option key={item.id} value={item.id}>{item.name}</option>
                    ))}
                </Select>
            </Flex>
            <Flex direction='row'>
                <Text
                    color='secondaryGray.600'
                    fontSize={{
                        base: "sm",
                    }}
                    fontWeight='400'
                    me='14px'>
                    Store Name:
                </Text>
                <Text
                    color={textColor}
                    fontSize={{
                        base: "sm",
                    }}
                    mb='5px'
                    fontWeight='bold'
                    me='14px'
                >
                    {store_name}
                </Text>
            </Flex>
            <Flex direction='row'>
                <Text
                    color='secondaryGray.600'
                    fontSize={{
                        base: "sm",
                    }}
                    fontWeight='400'
                    me='14px'>
                    Product Code
                </Text>
                <Text
                    color={textColor}
                    fontSize={{
                        base: "sm",
                    }}
                    fontWeight='bold'
                >
                    {prod_code}
                </Text>
                <Tooltip label={hasCopied ? "Copied!" : "Copy to Clipboard"}>
                    <IconButton
                        aria-label="Copy to Clipboard"
                        icon={<MdContentCopy />}
                        onClick={onCopy}
                        variant="ghost"
                        colorScheme={hasCopied ? "green" : "gray"}
                        height='17px'
                    />
                </Tooltip>

            </Flex>
            <Flex direction='row'>
                <Text
                    color='secondaryGray.600'
                    fontSize={{
                        base: "sm",
                    }}
                    fontWeight='400'
                    me='14px'>
                    Seller Name:
                </Text>
                <Input variant='filled' w='-moz-fit-content' placeholder='Seller Name' size="xs"
                    onChange={(e) => setDetails(prev => ({ ...prev, seller_name: e.target.value }))}
                    defaultValue={seller_name} />
            </Flex>
            <Flex direction='row'>
                <Text
                    color='secondaryGray.600'
                    fontSize={{
                        base: "sm",
                    }}
                    fontWeight='400'
                    me='14px'>
                    MRP&nbsp;:
                </Text>
                <Text
                    color={textColor}
                    fontSize={{
                        base: "sm",
                    }}
                    mb='5px'
                    fontWeight='bold'
                    me='14px'
                >
                    Rs.
                </Text>
                <Input variant='filled' w='-moz-fit-content' placeholder='MRP' size="xs"
                    onChange={(e) => setDetails(prev => ({ ...prev, mrp: e.target.value }))}
                    defaultValue={mrp} />
            </Flex>
            <Flex direction='row'>
                <Text
                    color='secondaryGray.600'
                    fontSize={{
                        base: "sm",
                    }}
                    fontWeight='400'
                    me='14px'>
                    Selling Price:
                </Text>
                <Text
                    color={textColor}
                    fontSize={{
                        base: "sm",
                    }}
                    fontWeight='semibold'
                    mb='5px'
                    me='14px'
                >
                    Rs.
                </Text>
                <Input variant='filled' w='-moz-fit-content' placeholder='Selling Price' size="xs"
                    onChange={(e) => setDetails(prev => ({ ...prev, selling_price: e.target.value }))}
                    defaultValue={selling_price} />
            </Flex>
        </>
    )
}

export default EditListing