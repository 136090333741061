import {
  Button,
  Flex,
  Image,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import logoWhite from "assets/img/layout/logoWhite.png";
import React from "react";
import logo from "assets/img/auth/auth.png"

export default function SidebarDocs() {
  const bgColor = "linear-gradient(135deg, #868CFF 0%, #4318FF 100%)";
  const borderColor = useColorModeValue("white", "navy.800");

  return (
    <Flex
      justify='center'
      direction='column'
      align='center'
      // bg={bgColor}
      // borderRadius='30px'
      mt='0'
      width="60%"
      height="70%"
      margin="auto"
      position='relative'>
      
      
      <Image src={logo} alt="Pirates Alert" />
    </Flex>
  );
}
