
import {
    Box,
    useColorModeValue,
    SimpleGrid,
    Icon,
    Flex,
    Select


} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import {
    MdAddTask,
    MdAttachMoney,
    MdBarChart,
    MdFileCopy,
    MdOutlineShoppingCart
} from "react-icons/md";
import { MdAccountCircle } from "react-icons/md";
import { FaBoxOpen } from "react-icons/fa";
import { IoIosPricetag } from "react-icons/io";


import React, { useState, useEffect } from "react";
import MiniStatistics from "components/card/MiniStatistics";


// import Tasks from "views/admin/keyword/components/Tasks";


export default function CounterfeitSellers() {
    // Chakra Color Mode
    const brandColor = useColorModeValue("brand.500", "white");
    const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
    const [data, setData] = useState([]);


    useEffect(() => {
        const userInfo = JSON.parse(localStorage.getItem('PAuser'));
        const uid = userInfo?.message?.result?.id;

        const fetchBrands = async () => {
            try {
                const response = await fetch("https://anticounterfeit.co.in/api/general/getCounts", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ uid }),
                });

                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }

                const data = await response.json();
                setData(data); // Assuming data is the array of NFT objects
            } catch (error) {
                console.error("Error fetching brands:", error);
            }
        };

        fetchBrands();
    }, []);



    return (
            <SimpleGrid
                columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
                gap='20px'
                mb='20px'>
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={MdAccountCircle} color={brandColor} />
                            }
                        />
                    }
                    name='Counterfeit Sellers'
                    value={data[0]}
                />
                <MiniStatistics
                    startContent={
                        <IconBox
                            w='56px'
                            h='56px'
                            bg={boxBg}
                            icon={
                                <Icon w='32px' h='32px' as={IoIosPricetag} color={brandColor} />
                            }
                        />
                    }
                    name='Counterfeited Brands'
                    value={data[1]}
                />
                <MiniStatistics startContent={
                    <IconBox
                        w='56px'
                        h='56px'
                        bg={boxBg}
                        icon={
                            <Icon w='32px' h='32px' as={MdOutlineShoppingCart} color={brandColor} />
                        }
                    />
                }
                    name='Counterfeit Listings'
                    value={data[2]}
                />

            </SimpleGrid>


    );
}
