import {
  Flex,
  Table,
  Checkbox,
  Tbody,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  CheckboxGroup,
  Stack,
  Image,
  Alert,
  AlertIcon
} from "@chakra-ui/react";
import React, { useContext } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import { useAuthContext } from "hooks/useAuthContext";
import { useState, useEffect } from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import Amazon from 'assets/img/amazon.ico';
import Flipkart from 'assets/img/flipkart.ico';
import Snapdeal from 'assets/img/snapdeal.ico';
import Meesho from 'assets/img/meesho.ico';
import Glowroad from 'assets/img/glowroad.ico';



export default function CheckTable() {
  const { user } = useAuthContext();
  const userInfo = JSON.parse(localStorage.getItem('PAuser'));


  const [keywords, setKeywords] = useState([]);
  const [inputKeyword, setInputKeyword] = useState('');
  const [inputManu, setInputManu] = useState('');
  const [inputBrand, setInputBrand] = useState('');
  const [inputError, setInputError] = useState(false);
  const [platforms, setPlatforms] = useState([]);
  const [selectedPlatforms, setSelectedPlatforms] = useState([]);
  const [insertKeywords, setInsertKeyword] = useState([]);
  const [editMode, setEditMode] = useState({});
  const [newEditMode, setNewEditMode] = useState({});

  const [successAlert, setSuccesAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const handleSuccessAlert = async (alertMessage) => {
    setSuccesAlert(alertMessage);
    setTimeout(() => {
      setSuccesAlert('');
    }, 5000);
  }
  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }


  const fetchKeywords = async () => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/keyword/getKeywords/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id // Assuming user is defined somewhere in your component
        })
      });

      const json = await response.json();

      if (response.ok) {
        setKeywords(json);
        setInsertKeyword([]);
        //console.log('keywords added')
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json.message);
        handleFailureAlert("Error  getting keywords");
      }
    } catch (e) {
      //console.log("error in getkeywords");
      handleFailureAlert("Error  getting keywords");

    }
  };
  const fetchPlatforms = async () => {
    try {
      const response = await fetch('https://anticounterfeit.co.in/api/platforms/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
      });
      const json = await response.json();
      if (response.ok) {
        setPlatforms(json);
        platforms.forEach((pf) => {
          setSelectedPlatforms[pf.id] = 'X';
        })
      } else if (response.status === 400) {
        //console.log(json);
        // alert(json);
        handleFailureAlert("Error Fetching Platforms");
      }
    } catch (e) {
      //console.log("error in getPlatforms");
      handleFailureAlert("Error Fetching Platforms");

    }
  };

  useEffect(() => {

    fetchKeywords();
    fetchPlatforms();
  }, [userInfo?.message?.result.id]);

  const headings = ['SR NO.', 'KEYWORDS', 'MANUFACTURER', 'BRAND', 'PLATFORM', '']

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleTempInsertKeyword = async () => {
    try {
      if (inputBrand === '' || inputKeyword === '' || inputManu === '' || selectedPlatforms.length === 0) {
        setInputError(true);
        return;
      } else if (inputBrand !== '' & inputKeyword !== '' & inputManu !== '') {
        setInputError(false);
      }
      //console.log(inputKeyword, inputManu, inputBrand, selectedPlatforms);
      let dataToSend = {
        user_id: userInfo.message.result.id,
        name: inputKeyword,
        manufacturer: inputManu,
        brand: inputBrand,
        platformIds: selectedPlatforms
      }
      await setInsertKeyword([...insertKeywords, dataToSend]);
      await setInputManu('');
      await setInputKeyword('');
      await setInputBrand('');
      // Reset the form fields
      // setSelectedPlatforms([]);

    } catch (err) {
      //console.log(err);
    }
  }

  const insert = async (data) => {
    try {
      const response = await fetch('https://anticounterfeit.co.in/api/keyword/insert', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          keywords: data
        })

      });

      const json = await response.json();

      if (response.ok) {
        //console.log(json)
        // alert("Changes Saved");
        handleSuccessAlert("Changes Saved");
        fetchKeywords();
      } else if (response.status === 400) {
        //console.log(json);
        // alert("error in insertion", json);
        handleFailureAlert("error in insertion");
      }
    } catch (err) {
      alert('error in insertion');
      //console.log('err');
      handleFailureAlert("error in insertion");

    }
  }

  const handleConfirm = async () => {
    if (insertKeywords === null) {
      handleSuccessAlert('No Changes to save');
      return;
    }
    insert(insertKeywords);
  }

  const handleDeleteButton = async (row) => {
    try {
      const confirm = window.confirm('Are you sure')
      if (!confirm) return;
      //console.log('deleting keyword==>', row.id)
      const response = await fetch('https://anticounterfeit.co.in/api/keyword/disable', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          ids: [row.id]
        })

      });

      const json = await response.json();

      if (response.ok) {
        //console.log(json);
        // alert('Successfully deleted the Keyword');
        handleSuccessAlert("Keyword Deleted");
        fetchKeywords();
      } else if (response.status === 400) {
        //console.log(json);
        // alert("error in insertion", json);
        handleFailureAlert("Error in deletion");
      }
    } catch (err) {
      // alert('error in insertion');
      handleFailureAlert("Error in deletion");
      //console.log('err');
    }
  }

  const handleNewDeleteButton = async (row) => {
    try {
      setInsertKeyword(insertKeywords.filter((item) => (item.name !== row.name && item.manufacturer !== row.manufacturer && item.brand !== row.brand)));

    } catch (err) {
      //console.log('error in deleting', err);
    }
  }

  const handleEditButton = async (row) => {
    try {
      setEditMode({ ...editMode, [row.id]: true });
    } catch (err) {
      //console.log('error in edit button')
    }
  }
  const handleSaveEdit = async (row) => {
    try {
      const response = await fetch('https://anticounterfeit.co.in/api/keyword/updatePlatform', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: row.id,
          pids: selectedPlatforms
        })
      });

      const json = await response.json();

      if (response.ok) {
        //console.log(json)
        // alert("Changes Saved");
        handleSuccessAlert("Changes Saved");
        await fetchKeywords();
        setEditMode({ ...editMode, [row.id]: false });
        await setSelectedPlatforms([]);
      } else if (response.status === 400) {
        //console.log(json);
        // alert("error in insertion", json);
        handleFailureAlert("Error in saving changes. Please try again!");
      }
    } catch (err) {
      // alert('error in save editted row');
      handleFailureAlert("Error in saving changes. Please try again!");
      //console.log("err in handleSaveEdit", err)
    }
  }



  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }} mb='20px'>
      <Flex px='25px' justify='space-between' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>

        </Text>
        {/* <Menu /> */}
      </Flex>
      <Table variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {/* {headerGroups.map((headerGroup, index) => ( */}
          <Tr  >
            {headings.map((column, index) => (
              <Th

                pe='10px'
                key={index}
                borderColor={borderColor}>
                <Flex
                  justify='space-between'
                  align='center'
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color='gray.400'>
                  {column}
                </Flex>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {keywords.map((row, index) => {
            return (
              <Tr key={index}>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "100px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.name}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.manufacturer}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.brand}
                    </Text>
                  </Flex>
                </Td>

                {!editMode[row.id] ?
                  <Td
                    fontSize={{ sm: "14px" }}
                    minW={{ sm: "150px", md: "200px", lg: "auto" }}
                    borderColor='transparent'>
                    <Flex align='center'>
                      {row.crawl_status_amazon !== 'X' && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Amazon}
                        alt='Amazon Icon'
                        mr='5px'
                      />}
                      {row.crawl_status_flipkart !== 'X' && <Image
                        boxSize='19px'
                        objectFit='cover'
                        src={Flipkart}
                        alt='flipkart Icon'
                        mr='5px'
                      />}
                      {row.crawl_status_snapdeal !== 'X' && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Snapdeal}
                        alt='snapdeal Icon'
                        mr='2px'
                      />}
                      {row.crawl_status_meesho !== 'X' && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Meesho}
                        alt='Meesho Icon'
                        mr='2px'
                      />

                      }
                      {row.crawl_status_glowroad !== 'X' && <Image
                        boxSize='15px'
                        objectFit='cover'
                        src={Glowroad}
                        alt='Meesho Icon'
                        mr='2px'
                      />
                      }
                    </Flex>
                  </Td> :
                  <Td>
                    <Stack pl={6} mt={1} spacing={1} >
                      <Checkbox
                        isChecked={selectedPlatforms.length === platforms.length}
                        onChange={(e) => {
                          if (e.target.checked) {
                            const allPlatformIds = platforms.map((platform) => platform.id);
                            setSelectedPlatforms(allPlatformIds);
                          } else {
                            setSelectedPlatforms([]);
                          }
                        }}
                      >
                        ALL
                      </Checkbox>
                      {platforms.map((platform, index) => {
                        return (
                          <Checkbox
                            key={index}
                            isChecked={selectedPlatforms.includes(platform.id)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedPlatforms([...selectedPlatforms, platform.id]);
                              } else {
                                setSelectedPlatforms(selectedPlatforms.filter((id) => id !== platform.id));
                              }
                            }}
                          >
                            {platform.platform_name}
                          </Checkbox>
                        )
                      })}

                    </Stack>
                  </Td>
                }
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Button colorScheme="gray" onClick={() => handleDeleteButton(row)}>
                      <Icon as={DeleteIcon} />
                    </Button>
                    {!editMode[row.id] ?
                      <Button colorScheme="gray" onClick={() => handleEditButton(row)}>
                        <Icon as={EditIcon} />
                      </Button>
                      : <Button colorScheme='messenger' size='md' onClick={() => handleSaveEdit(row)}>
                        Save
                      </Button>

                    }
                  </Flex>
                </Td>
              </Tr>

            );
          })}
          {insertKeywords.map((row, index) => {
            return (
              <Tr key={index}>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {keywords.length + index + 1}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.name}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.manufacturer}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Text color={textColor} fontSize='md' fontWeight='700'>
                      {row.brand}
                    </Text>
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    {row.platformIds.includes(1) && <Image
                      boxSize='15px'
                      objectFit='cover'
                      src={Amazon}
                      alt='Amazon Icon'
                      mr='5px'
                    />}
                    {row.platformIds.includes(2) && <Image
                      boxSize='19px'
                      objectFit='cover'
                      src={Flipkart}
                      alt='flipkart Icon'
                      mr='5px'
                    />}
                    {row.platformIds.includes(3) && <Image
                      boxSize='15px'
                      objectFit='cover'
                      src={Snapdeal}
                      alt='snapdeal Icon'
                      mr='2px'
                    />}
                  </Flex>
                </Td>
                <Td
                  fontSize={{ sm: "14px" }}
                  minW={{ sm: "150px", md: "200px", lg: "auto" }}
                  borderColor='transparent'>
                  <Flex align='center'>
                    <Button colorScheme="gray" onClick={() => handleNewDeleteButton(row)}>
                      <Icon as={DeleteIcon} />
                    </Button>
                  </Flex>
                </Td>
              </Tr>

            );
          })}
          <Tr>
            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "150px", md: "150px", lg: "auto" }}
              borderColor='transparent'>

            </Td>

            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "100px", md: "150px", lg: "auto" }}
              borderColor='transparent'>
              <Flex align='left' w='75%'>
                <Input
                  isRequired={true}
                  onChange={(e) => setInputKeyword(e.target.value)}
                  // variant='auth'
                  fontSize='md'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Keyword'
                  mb='0px'
                  pb='0px'
                  fontWeight='500'
                  size='sm'
                  errorBorderColor={inputKeyword === '' && inputError ? 'crimson' : undefined}
                  value={inputKeyword}
                />
              </Flex>
            </Td>

            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "150px", md: "200px", lg: "auto" }}
              borderColor='transparent'>
              <Flex align='left' w='75%'>
                <Input
                  isRequired={true}
                  onChange={(e) => setInputManu(e.target.value)}
                  // variant='auth'
                  fontSize='md'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Manufacturer'
                  mb='0px'
                  pb='0px'
                  fontWeight='500'
                  size='sm'
                  errorBorderColor={inputManu === '' && inputError ? 'crimson' : undefined}
                  value={inputManu}
                />
              </Flex>
            </Td>

            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "150px", md: "200px", lg: "auto" }}
              borderColor='transparent'>
              <Flex align='left' w='75%'>
                <Input
                  isRequired={true}
                  onChange={(e) => setInputBrand(e.target.value)}
                  fontSize='md'
                  ms={{ base: "0px", md: "0px" }}
                  type='text'
                  placeholder='Brand'
                  mb='0px'
                  pb='0px'
                  fontWeight='500'
                  size='sm'
                  errorBorderColor={inputBrand === '' && inputError ? 'crimson' : undefined}
                  value={inputBrand}
                />
              </Flex>
            </Td>
            <Td></Td>
            <Td
              fontSize={{ sm: "14px" }}
              minW={{ sm: "100px", md: "200px", lg: "auto" }}
              borderColor='transparent'>
              <Button colorScheme='messenger' size='md' onClick={handleTempInsertKeyword}>
                Add+
              </Button>
            </Td>
          </Tr>

          <Tr
            fontSize={{ sm: "14px" }}
            minW={{ sm: "150px", md: "200px", lg: "auto" }}
            borderColor='transparent'>
            <Td>&nbsp;</Td>
            <Td colSpan={3} pl='0px' pt='-10px'>
              <Stack pl={6} mt={1} spacing={1} direction='row'>
                <Checkbox
                  isChecked={selectedPlatforms.length === platforms.length}
                  onChange={(e) => {
                    if (e.target.checked) {
                      const allPlatformIds = platforms.map((platform) => platform.id);
                      setSelectedPlatforms(allPlatformIds);
                    } else {
                      setSelectedPlatforms([]);
                    }
                  }}
                >
                  ALL
                </Checkbox>
                {platforms.map((platform, index) => {
                  return (
                    <Checkbox
                      key={index}
                      isChecked={selectedPlatforms.includes(platform.id)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedPlatforms([...selectedPlatforms, platform.id]);
                        } else {
                          setSelectedPlatforms(selectedPlatforms.filter((id) => id !== platform.id));
                        }
                      }}
                    >
                      {platform.platform_name}
                    </Checkbox>
                  )
                })}

              </Stack>
            </Td>
            <Td>
              <Flex align='center' w='100%' display={inputError ? '' : 'none'}>
                <Text color='red' fontSize='md' fontWeight='700'>
                  Empty Fields
                </Text>
              </Flex>
            </Td>
          </Tr>
          <Tr bottom="0px" pos="absolute" right='10px'>
            <Td colSpan={4} borderColor='transparent'></Td>

            <Td colSpan={2} borderColor='transparent' right="10px">
              <Button colorScheme='messenger' size='md' onClick={handleConfirm}>
                Save Changes
              </Button>
            </Td>
          </Tr>

        </Tbody>
      </Table>
      <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
        {successAlert !== '' &&
          <Alert status='success' variant='left-accent' w='40vw'  >
            <AlertIcon />
            {successAlert}

          </Alert>
        }
        {failureAlert !== '' &&
          <Alert status='error' variant='left-accent' w='40vw'>
            <AlertIcon />
            {failureAlert}
          </Alert>
        }
      </Stack>
    </Card>
  );
}
