// ListContext.js
import React, { createContext, useState, useContext } from 'react';

const SuspectedProductContext = createContext();

export const useSuspiciousProductContext = () => {
  return useContext(SuspectedProductContext);
};

export const SuspiciousProductProvider = ({ children }) => {
  const [suspiciousProducts, setSuspiciousProducts] = useState([]);
  

  return (
    <SuspectedProductContext.Provider value={{ suspiciousProducts, setSuspiciousProducts }}>
      {children}
    </SuspectedProductContext.Provider>
  );
};
