
import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  Flex,
  Grid,
  Link,
  Text,
  useColorModeValue,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Stack,
  Alert,
  AlertIcon
} from "@chakra-ui/react";

// Custom components
import HistoryItem from "views/admin/testProducts/components/HistoryItem";
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import { useListContext } from '../../../contexts/listContext';



import Card from "components/card/Card.js";



export default function Products() {

  // Chakra Color Mode
  const [abuseCulprit, setAbuseCulprit] = useState([]);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const userInfo = JSON.parse(localStorage.getItem('PAuser'));
  const [productsToShow, setProductsToShow] = useState(20); // Initial number of products to show
  const productsPerPage = 20; // Number of products to load per page
  const [sortedProducts, setSortedProducts] = useState([]);



  const [productByStatus, setProductByStatus] = useState([]);
  const [testProducts, setTestProducts] = useState([]);
  const [insertSeller, setInsertSeller] = useState([]);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const { list, checkedPlatforms, dateFilter } = useListContext();

  const [successAlert, setSuccesAlert] = useState('');
  const [failureAlert, setFailureAlert] = useState('');

  const handleSuccessAlert = async (alertMessage) => {
    setSuccesAlert(alertMessage);
    setTimeout(() => {
      setSuccesAlert('');
    }, 5000);
  }
  const handleFailureAlert = async (alertMessage) => {
    setFailureAlert(alertMessage);
    setTimeout(() => {
      setFailureAlert('');
    }, 5000);
  }






  const fetchProductsByStatus = async (status) => {
    try {
      //console.log("param", userInfo.message.result.id);
      const response = await fetch('https://anticounterfeit.co.in/api/products/byStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          uid: userInfo.message.result.id,
          status: status
        })
      });

      const json = await response.json();

      if (response.ok) {
        // setProductByStatus(json);
        setFetchedProducts(json);
        //console.log('status Products fetched')
      } else if (response.status === 400) {
        //console.log(json);
        handleFailureAlert("Error fetching products");
      }
    } catch (e) {
      //console.log("error in fetchProducts");
      handleFailureAlert("Error fetching products");

    }
  };


  const loadMore = () => {
    setProductsToShow(productsToShow + productsPerPage);
  };
  const loadLess = () => {
    setProductsToShow(productsToShow - productsPerPage);
  };

  const handleUpdateProduct = async (psm_id, test_purchase_status) => {
    try {
      const existingIndex = testProducts.findIndex(item => item.psm_id === psm_id);
      if (existingIndex !== -1) {
        // Product already exists, update its status
        setTestProducts(prev => {
          const updatedProd = [...prev];
          updatedProd[existingIndex].test_purchase_status = test_purchase_status;
          return updatedProd;
        });
      } else {
        // Product does not exist, add it to the list
        setTestProducts([...testProducts, { psm_id, test_purchase_status }]);
      }
      //console.log('testProducts', testProducts);
    } catch (err) {
      console.error('err in handleInserSeller', err)
    }
  }

  useEffect(() => {
    fetchProductsByStatus('T');
  }, [userInfo?.message?.result.id]);


  const removeProductFromUpdate = async (psm_id, test_purchase_status) => {
    setTestProducts(testProducts.filter((item) => {
      return !(item.psm_id === psm_id && item.test_purchase_status === test_purchase_status);
    }));
    //console.log('removed from list', testProducts);
  }

  const handleUpdateSeller = async (product, seller_status) => {
    try {
      const existingIndex = insertSeller.findIndex(item => item.product.psm_id === product.psm_id);

      if (existingIndex !== -1) {
        // Product already exists, update its status
        setInsertSeller(prev => {
          const updatedSeller = [...prev];
          updatedSeller[existingIndex].seller_status = seller_status;
          return updatedSeller;
        });
      } else {
        // Product does not exist, add it to the list
        setInsertSeller([...insertSeller, { product, seller_status }]);
      }
      //console.log('updateSeller', insertSeller);
    } catch (err) {
      console.error('err in handleInserSeller', err)
    }
  }



  const removeFromUpdate = async (product, seller_status) => {
    setInsertSeller(insertSeller.filter((item) => {
      return !(item.product.psm_id === product.psm_id);
    }));
    //console.log('removed from list new Update Seller:', insertSeller);
  }

  const handleInsertAbuse = async (product, abuse_type) => {
    try {
      const existingIndex = abuseCulprit.findIndex(item => item.product.psm_id === product.psm_id);

      if (existingIndex !== -1) {
        // Product already exists, update its status
        setAbuseCulprit(prev => {
          const updated = [...prev];
          updated[existingIndex].abuse_type = abuse_type;
          return updated;
        });
      } else {
        // Product does not exist, add it to the list
        setAbuseCulprit([...abuseCulprit, { product, abuse_type }]);
      }
      //console.log('abused', abuseCulprit);
    } catch (err) {
      console.error('err in handleInserAbuse', err)
    }
  }



  const handleSaveChanges = async () => {
    try {

      const response = await fetch('https://anticounterfeit.co.in/api/test/updateStatus', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          products: testProducts
        })
      });
      const json = await response.json();
      if (response.ok) {
        // setProductByStatus('T');
        fetchProductsByStatus('T');
        //console.log('test status updated ')
        // handleSuccessAlert("Changes Saved");

      } else if (response.status === 400) {
        //console.log(json);
        handleFailureAlert("Error saving changes. Please try again");
      }

      //console.log("initiate update", insertSeller);
      const uid = JSON.parse(localStorage.getItem("PAuser")).message.result.id;
      const response1 = await fetch('https://anticounterfeit.co.in/api/sellers/updateSellerStatus/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          sellers: insertSeller,
          uid: uid
        })
      });

      const json1 = await response1.json();

      if (response1.ok) {
        handleSuccessAlert("Changes Saved");
        fetchProductsByStatus('T');
        //console.log('Empty insert seller', insertSeller)
      } else if (response.status === 400) {
        //console.log(json1);
        handleFailureAlert("Error saving changes. Please try again");
      }

      const abuseTypeSave = abuseCulprit;
      //console.log("abuseTypeSave",);
      // if (abuseTypeSave.length!==abuseTypeSave0) {
      const abused = abuseTypeSave.map((item) => ({
        psm_id: item.product.psm_id,
        abuse_type: item.abuse_type
      }));

      const response2 = await fetch('https://anticounterfeit.co.in/api/products/reportAbuse/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          products: abused
        })
      });
      const json2 = await response2.json();
      //console.log("checkpoint", response2);

      if (response2.ok) {
        //console.log("Abuse TYPE Updated");
        await fetchProductsByStatus('T');
        // await handleFilter();
        handleSuccessAlert("Changes Saved!");
        //console.log('Empty abuse Culprit', abuseTypeSave);
      } else if (response2.status === 400) {
        //console.log(json2);
        //console.log('error in report abuse', json2.message);
        handleFailureAlert("Failed to Save Changes. Please try again!");
      }
    } catch (e) {
      //console.log("error in handleSavechanges");
      handleFailureAlert("Error saving changes. Please try again");

    }
  }

  const handleSearch = async (text) => {
    try {
      // setProducts(fetchedProducts);
      if (text) {
        setProductByStatus(sortedProducts.filter((item) =>
          item.title?.toLowerCase().includes(text?.toLowerCase()) ||
          item.keyword?.toLowerCase().includes(text?.toLowerCase()) ||
          item.seller_name?.toLowerCase().includes(text?.toLowerCase()) ||
          item.prod_code?.toLowerCase().includes(text?.toLowerCase())
        ));
      } else {
        setProductByStatus(sortedProducts);
        handleFilter();
      }
    } catch (e) {
      console.error('error in search', e)
    }
  }

  const handleFilter = async () => {
    try {
      let keywordNames = list.map((item) => item.name?.toLowerCase());
      let platformIds = checkedPlatforms.map((item) => item.id);
      let allProducts = fetchedProducts;
      let kfilter;
      let pfilter;
      let dateSort
      // if (keywordNames.length !== 0) {
        kfilter = allProducts.filter((item) =>
          keywordNames?.includes(item.keyword)
        )
      // } else {
        // kfilter = allProducts;
      // }
      // if (platformIds.length !== 0) {
        pfilter = kfilter.filter((item) =>
          platformIds?.includes(item.platform_id)
        )
      // } else {
        // pfilter = kfilter;
      // }
      let startDate = new Date(dateFilter.startDate);
      let endDate = new Date(dateFilter.endDate);
      //console.log("DATE RANGE", startDate, endDate);

      dateSort = await pfilter.filter((item) => {
        let date = new Date(item.date_test);
        if (date >= startDate && date <= endDate) {
          return true;
        }
      })
      //console.log("dateSort", dateSort)

      setProductByStatus(dateSort);
      setSortedProducts(dateSort);


    } catch (err) {
      //console.log('error in handleFilter', err)
    }
  }
  useEffect(() => {
    handleFilter();
  }, [list, checkedPlatforms, fetchedProducts, dateFilter]);






  return (

    <Box pt={{ base: "180px", md: "80px", xl: "80px" }} >
      {/* Main Fields */}
      <Card p='0px' w='100%'>
        <Text px='20px' pt='10px'>Total Products: {fetchedProducts.length}</Text>

        <Flex
          align={{ sm: "flex-start", lg: "center" }}
          justify='space-between'
          w='100%'
          px='22px'
          py='18px'>
          <SearchBar m="10px" borderRadius="30px" onChange={(e) => handleSearch(e.target.value)} />
          <Button variant='action' alignSelf='right' onClick={() => { handleSaveChanges() }} >Save Changes</Button>
        </Flex>
        {Array.isArray(productByStatus) && productByStatus.slice(0, productsToShow).map((prod, index) => {
          return (
            <HistoryItem
              key={index}
              index={index}
              product={prod}
              handleUpdateProduct={handleUpdateProduct}
              removeFromUpdate={removeProductFromUpdate}
              handleInsertSeller={handleUpdateSeller}
              removeFromInsert={removeFromUpdate}
              handleInsertAbuse={handleInsertAbuse}

            />
          )
        })}

      </Card>

      <Stack spacing={3} position='fixed' right='10px' bottom='2vh'>
        {successAlert !== '' &&
          <Alert status='success' variant='left-accent' w='40vw'  >
            <AlertIcon />
            {successAlert}

          </Alert>
        }
        {failureAlert !== '' &&
          <Alert status='error' variant='left-accent' w='40vw'>
            <AlertIcon />
            {failureAlert}
          </Alert>
        }
      </Stack>

      <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadLess()} r='0' position='absolute' right={{ base: '10%', md: '15%', sm: '30%' }} textDecoration='underline'>
        Show less...
      </Text></Link>
      <Link ><Text color={textColor} fontSize='l' fontWeight='500' onClick={() => loadMore()} r='0' position='absolute' right={{ base: '2%', md: '5%' }} textDecoration='underline'>
        Show more...
      </Text></Link>

      {/* Delete Product */}
    </Box>


  );
}
