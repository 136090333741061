
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
  Grid, GridItem,
  Link,
 Text,
} from "@chakra-ui/react";


import IconBox from "components/icons/IconBox";
import React from "react";

import CheckTable from "views/admin/product_info/components/CheckTable";

import Tasks from "views/admin/keyword/components/Tasks";


export default function UserReports() {
  const textColor = useColorModeValue("brands.900", "white");
  const bgItem = useColorModeValue(
    { bg: "white", boxShadow: "0px 40px 58px -20px rgba(112, 144, 176, 0.12)" },
    { bg: "navy.700", boxShadow: "unset" }
  );
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  return (
    
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
     
        <CheckTable />
        {/* <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        /> */}
     
    </Box>
  );
}
