import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { BsArrowRightCircleFill } from "react-icons/bs";
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap CSS
import './Modal.css'; // Import the updated CSS file
import emailjs from '@emailjs/browser'

const CustomModal = ({ entries, show, onClose, setShowPopup, showPopup }) => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    

    const handleSubmit = (ev) => {
        ev.preventDefault();
        const companyDomains = [
            'gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com', 'aol.com', 'zoho.com',
            'icloud.com', 'protonmail.com', 'mail.com', 'gmx.com', 'yandex.com',
        ];

        const isPersonalEmail = (email) => {
            const domain = email.split('@')[1];
            return companyDomains.includes(domain);
        };

        if (email === '') {
            setError('Please enter an email address.');
            return;
        }

        if (isPersonalEmail(email)) {
            setError('Please enter a company email address.');
            return;
        }

        setError('');

        emailjs.send(
            'service_mcioqnj', // Service ID
            'template_c1vgnrh', // Template ID
            {
                to_email: 'mayur.kachare@piratesalert.com',
                entries: entries,
                email: email
            },
            'qCtXJCBtmkzY3H6tB' // User ID or Public Key
        )
            .then((response) => {
                setShowPopup(true);
                onClose();
            })
            .catch((error) => {
                console.error('Failed to send email:', error);
                alert("Server Busy, Please try again later");
                setError('Server Busy, Please try again later.');
            });
    };

    return (
        <Modal show={show}  centered>
            <Modal.Body className="p-4">
                <div className="modal-content">
                    <h1 className="mb-4">Get Counterfeit Alerts Directly in Your Inbox.
                        {/* <span className='highlight'>24 hours!</span> */}
                    </h1>
                    <Form onSubmit={handleSubmit}>
                        <div className="d-flex align-items-center input-group">
                            <input
                                type="email"
                                placeholder="name@company.com"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                className="email-input me-2 ms-2 form-control-plaintext"
                            />
                            <Button
                                type="submit"
                                variant="link"
                                className="submit-button"
                            >
                                <BsArrowRightCircleFill size={27}/>
                            </Button>
                        </div>
                        {error && <p className="error-message">{error}</p>}
                    </Form>
                    <div className='content mt-4'>
                        <p>*Scanning has been initiated. It usually takes 4 to 24 Hours.</p>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CustomModal;
